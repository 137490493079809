import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

import CollapsibleGroup from "@ui/CollapsibleGroup";
import ContentList from "features/forms/MultiContentForm/Components/ContentList";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "@ui/Button";
// import { Modal, useModal } from "@ui/Modal";
import JobSORHistory from "@ui/JobSORHistory";
import SORItem from "@ui/SORItem";
import { Modal, useModal } from "@ui/Modal";
import SearchBar from "@ui/SearchBar";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // qty: yup
    //   .number()
    //   .required("This field is required")
    //   .typeError("Quantity must be a number"),
    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewJobSors({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });
  const [dataTracking, setDataTracking] = useState([]); // Keeps track of ORIGINAL data
  const [contentListData, setContentListData] = useState([]); // For the content list & Keeps track of newly added items
  const [sorQueue, setSorQueue] = useState([]); // For UI display of what is being done
  const [stagingData, setStagingData] = useState([]); // For what is used to send API data
  const [selectedContentListItem, setSelectedContentListItem] = useState(null); // tracks clicked content list item

  const { tableRowId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sorCode: "",
      shortDesc: "",
      uomId: "",
      uomName: "",
      rate: "",
      qty: "",
      subTotal: 0,
      sorRefId: 0,
      versionNum: "",
    },
  });

  // Fields being used
  const qtyValue = watch("qty");
  const rate = watch("rate");
  const sorRefId = getValues("sorRefId");
  const subTotal = getValues("subTotal");

  // Calculate subTotal whenever qty or rate changes
  useEffect(() => {
    const subTotal = qtyValue && rate ? qtyValue * rate : 0;
    setValue("subTotal", subTotal);
  }, [qtyValue, rate, setValue]);

  const { openModal } = useModal();
  // const { data: stateData } = state;

  useEffect(() => {
    // console.log("stateData:", stateData);
    // console.log("dropdownData:", dropdownData);
    // console.log("contentListData:", contentListData);
    console.log("stagingData:", stagingData);
    // console.log("sorQueue:", sorQueue);
    // console.log("dataTracking:", dataTracking);
  }, [stagingData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // Get ContentList data
  useEffect(() => {
    // API GET contentList data
    Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
      // console.log("contentList data", data);
      setContentListData(data);
      setStagingData(data);
      setDataTracking(data);
    });
  }, [tableRowId]);

  // Fill form via 1st item from ContentList data array
  useEffect(() => {
    if (contentListData && contentListData.length > 0) {
      setSelectedContentListItem(contentListData[0]);

      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: contentListData[0].id,
        // * BOOLEANS need || "" removed!
        sorCode: contentListData[0]?.sor.sorCode || "",
        shortDesc: contentListData[0]?.sor.shortDesc || "",
        uomId: contentListData[0]?.sor.uomId || "",
        uomName: contentListData[0]?.sor.uom.name || "",
        rate: contentListData[0]?.sor.rate || "",
        qty: contentListData[0]?.qty || "",
        subTotal: contentListData[0]?.subTotal || "",
        sorRefId: contentListData[0]?.sorRefId || "",
        versionNum: contentListData[0]?.versionNum || "",
      });
    }
  }, [contentListData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        // cleanURL();
        Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
          setContentListData(data);
        });
        setSorQueue([]);
      }

      const dataObject = stagingData.map((item) => ({
        sorId: item.sorRefId,
        qty: item.qty,
      }));

      // console.log("handleSubmitForm data:", data);
      console.log("handleSubmitForm dataObject:", dataObject);

      if (dataObject.length > 0) {
        Hooks.sendData(
          dataObject,
          `${API_ENV}/jobsor/masscreateupdate/${tableRowId}`,
          "POST",
          thenFunction
        );
      } else {
        Hooks.sendData(
          dataObject,
          `${API_ENV}/jobsor/massdelete/${tableRowId}`,
          "DELETE",
          thenFunction
        );
      }
    },
    [refreshTable, tableRowId, stagingData]
  );

  const addSORToQueue = async (e) => {
    e.preventDefault();

    // Set the error for qty if no value or not a number
    if (!qtyValue || isNaN(qtyValue)) {
      setError("qty", {
        type: "manual",
        message: "Quantity is required and must be a valid number.",
      });
      return;
    }

    // Clear any previous errors on qty
    clearErrors("qty");
    clearErrors("sorCode");

    // Find the item in stagingData with a matching sorRefId
    const existingStagingDataItem = stagingData.find(
      (item) => item.sorRefId === sorRefId
    );

    // Find the item in dataTracking with a matching sorRefId
    const existingDataTrackingItem = dataTracking.find(
      (item) => item.sorRefId === sorRefId
    );

    if (existingStagingDataItem) {
      // console.log("addSORToQueue: Existing stagingData item found");

      // Use existingEntry as the base for updatedItem
      const updatedItem = {
        ...existingStagingDataItem,
        qty: qtyValue,
        subTotal: subTotal,
        stagingType:
          existingStagingDataItem.stagingType === "create"
            ? "create"
            : "update",
      };

      // Update stagingData by modifying only the matching item's qty and subTotal
      setStagingData((prevData) =>
        prevData.map((item) =>
          item.sorRefId === sorRefId ? updatedItem : item
        )
      );

      // Update sorQueue to reflect the change
      setSorQueue((prevQueue) =>
        prevQueue.some((item) => item.sorRefId === sorRefId)
          ? prevQueue.map((item) =>
              item.sorRefId === sorRefId ? updatedItem : item
            )
          : [...prevQueue, updatedItem]
      );
    } else if (existingDataTrackingItem) {
      // console.log("addSORToQueue: Existing dataTracking item found");

      // Use existingEntry as the base for updatedItem
      const updatedItem = {
        ...existingDataTrackingItem,
        qty: qtyValue,
        subTotal: subTotal,
        stagingType:
          existingDataTrackingItem.stagingType === "create"
            ? "create"
            : "update",
      };

      // Update stagingData by modifying only the matching item's qty and subTotal
      setStagingData((prevData) => [...prevData, updatedItem]);

      // Update sorQueue to reflect the change
      setSorQueue((prevQueue) =>
        prevQueue.some((item) => item.sorRefId === sorRefId)
          ? prevQueue.map((item) =>
              item.sorRefId === sorRefId ? updatedItem : item
            )
          : [...prevQueue, updatedItem]
      );
    } else {
      console.log("addSORToQueue: No existing item found");
    }
  };

  function deleteSelectedSOR(e) {
    e.preventDefault();

    // Return if no item is selected
    if (!selectedContentListItem) {
      console.log("No item selected for deletion.");
      return;
    }

    // Find the item in sorQueue
    const existingSorQueueItem = sorQueue.find(
      (item) => item.sorRefId === selectedContentListItem.sorRefId
    );

    // Check if the item exists in dataTracking (original API data)
    const originalItem = dataTracking.find(
      (item) => item.sorRefId === selectedContentListItem.sorRefId
    );

    if (originalItem) {
      // console.log(
      //   "deleteSelectedSOR: Item found in original API data. Removing from stagingData but keeping in contentListData."
      // );

      const updatedItem = {
        ...originalItem,
        stagingType: "delete",
      };

      if (existingSorQueueItem) {
        // console.log("existingSorQueueItem TRUE");

        // Update the existing item’s stagingType to "delete"
        setSorQueue((prevData) =>
          prevData.map((item) =>
            item.sorRefId === selectedContentListItem.sorRefId
              ? { ...item, stagingType: "delete" }
              : item
          )
        );
      } else {
        // console.log("existingSorQueueItem FALSE");
        setSorQueue((prevData) => [...prevData, updatedItem]);
      }
    } else {
      // console.log(
      //   "deleteSelectedSOR: Item not found in original API data. Removing from both stagingData and contentListData."
      // );

      // Remove the item from contentListData as it's newly added
      setContentListData((prevData) =>
        prevData.filter(
          (item) => item.sorRefId !== selectedContentListItem.sorRefId
        )
      );

      setSorQueue((prevData) =>
        prevData.filter(
          (item) => item.sorRefId !== selectedContentListItem.sorRefId
        )
      );
    }

    // Always remove the selectedContentListItem from stagingData
    setStagingData((prevData) =>
      prevData.filter(
        (item) => item.sorRefId !== selectedContentListItem.sorRefId
      )
    );
  }

  const deleteFromSorQueue = (indexToDelete) => {
    // Get the item to delete from sorQueue
    const itemToDelete = sorQueue[indexToDelete];

    // Check if the item exists in dataTracking (original API data)
    const originalItem = dataTracking.find(
      (item) => item.sorRefId === itemToDelete.sorRefId
    );

    // Check if itemToDelete already exists in stagingData
    const itemExistsInStaging = stagingData.some(
      (item) => item.sorRefId === itemToDelete.sorRefId
    );

    // If the item exists in dataTracking, it was a pre-existing item
    if (originalItem) {
      // console.log(
      //   "deleteFromSorQueue: item found in orginal API data: item restored in stagingData"
      // );

      // If it exists, replace it; if not, add originalItem as a new entry
      if (itemExistsInStaging) {
        setStagingData((prevData) =>
          prevData.map((item) =>
            item.sorRefId === itemToDelete.sorRefId ? originalItem : item
          )
        );
      } else {
        setStagingData((prevData) => [...prevData, originalItem]);
      }
    } else {
      // console.log(
      //   "deleteFromSorQueue: item not found in orginal API data: item removed from stagingData & contentListData"
      // );

      setStagingData((prevData) =>
        prevData.filter((item) => item.sorRefId !== itemToDelete.sorRefId)
      );

      setContentListData((prevData) =>
        prevData.filter((item) => item.sorRefId !== itemToDelete.sorRefId)
      );
    }

    // Finally, remove the item from sorQueue to update the UI
    setSorQueue((prevQueue) =>
      prevQueue.filter((_, index) => index !== indexToDelete)
    );
  };

  function handleSearchBarClick(searchBarItem) {
    // console.log("searchBarItem: ", searchBarItem);

    // Check if contentListItem already exists in stagingData
    const existsInStagingData = stagingData.some(
      (item) => item.sorRefId === searchBarItem.sorRefId
    );

    if (!existsInStagingData) {
      setContentListData((prevData) => [...prevData, searchBarItem]);
      setStagingData((prevData) => [...prevData, searchBarItem]);
      setSorQueue((prevQueue) => [...prevQueue, searchBarItem]);
      // setDataTracking((prevQueue) => [...prevQueue, searchBarItem]);
    }
  }

  function contentListItemClicked(contentListItem) {
    // Directly use the passed item object to update the form
    // No need to parse eventData from the event.target.getAttribute
    // console.log("contentListItem: ", contentListItem);

    setSelectedContentListItem(contentListItem);

    reset({
      // * BOOLEANS need || "" removed!
      // * Date values & others need to be null if there is no data!
      id: contentListItem?.id,
      sorCode: contentListItem?.sor.sorCode || "",
      shortDesc: contentListItem?.sor.shortDesc || "",
      uomId: contentListItem?.sor.uomId || "",
      uomName: contentListItem?.sor.uom.name || "",
      rate: contentListItem?.sor.rate || "",
      qty: contentListItem?.qty || "",
      subTotal: contentListItem?.subTotal || "",
      sorRefId: contentListItem?.sorRefId || "",
      versionNum: contentListItem?.versionNum || "",
    });
  }

  const handleArchive = useCallback(
    (e) => {
      e.preventDefault();

      Swal.fire({
        title: "Archive item?",
        text: "Are you sure you wish to archive this item? This cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          function thenFunction(response) {
            Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
              setContentListData(data);
            });
          }

          Hooks.sendData(
            {},
            `${API_ENV}/jobsor/massdelete/${tableRowId}`,
            "DELETE",
            thenFunction
          );
        }
      });
    },
    [tableRowId]
  );

  const handleAddSorToJob = useCallback(() => {
    setFormState({ type: "addsortojob", data: {} });
  }, [setFormState]);

  const handleOpenModal = useCallback(async () => {
    openModal("Example");
  }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Commit Changes", click: handleSubmit(handleSubmitForm) },
      // { text: "Add SOR To Job", click: handleAddSorToJob },
      // { text: "Archive Job SOR", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleAddSorToJob,
    handleArchive,
    // handleOpenModal,
  ]);

  // function deleteAllSors(e) {
  //   e.preventDefault();

  //   Swal.fire({
  //     title: "Delete All Job SORs?",
  //     text: "Are you sure you wish to archive all Job SORs for this Job? This cannot be undone.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       function thenFunction(response) {
  //         Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
  //           setContentListData(data);
  //         });
  //       }

  //       Hooks.sendData(
  //         {},
  //         `${API_ENV}/jobsor/massdelete/${tableRowId}`,
  //         "DELETE",
  //         thenFunction
  //       );
  //     }
  //   });
  // }

  return (
    <Form>
      <div className="contentListContainer">
        <SearchBar
          title={"SOR"}
          type={"jobsor"}
          outsideFormData={contentListData}
          setOutsideFormData={setContentListData}
          onClick={handleSearchBarClick}
        />

        <ContentList
          data={contentListData}
          type={"jobsors"}
          clicked={contentListItemClicked}
        />
      </div>

      {contentListData.length > 0 && (
        <SidePanelFormRow type="full">
          <Button
            size="small"
            type="danger"
            onClick={(e) => deleteSelectedSOR(e)}
            style={{ marginBottom: "1rem" }}
          >
            Delete Selected Job SOR
          </Button>
        </SidePanelFormRow>
      )}

      {contentListData.length > 0 && (
        <>
          <CollapsibleGroup title="Form">
            <div className="sidePanelFormRowsContainer">
              <SidePanelFormRow>
                <Input
                  label={"SOR Code"}
                  name="sorCode"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"Short Description"}
                  name="shortDesc"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"UOM"}
                  name="uomName"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"Rate"}
                  name="rate"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"Quantity"}
                  name="qty"
                  type="number"
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"Sub Total"}
                  name="subTotal"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <Input
                  label={"Version Number"}
                  name="versionNum"
                  readOnly
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>

              <SidePanelFormRow type="full">
                <div className="buttonsGroup">
                  <Button size="small" onClick={addSORToQueue}>
                    Update
                  </Button>
                  {/* <Button size="small" type="danger" onClick={handleArchive}>
                    Delete Job SOR
                  </Button> */}
                </div>
              </SidePanelFormRow>
            </div>
          </CollapsibleGroup>

          <CollapsibleGroup title="SOR's to Edit">
            <div>
              <SORItem data={sorQueue} onDelete={deleteFromSorQueue} />
            </div>
          </CollapsibleGroup>

          {/* <CollapsibleGroup title="Version History">
            <JobSORHistory />
          </CollapsibleGroup> */}
        </>
      )}

      {contentListData.length > 0 && (
        <SidePanelFormRow type="full">
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            View History
          </Button>
        </SidePanelFormRow>
      )}

      <Modal name="Example" title={"Job SOR History"}>
        <JobSORHistory />
      </Modal>
    </Form>
  );
}
