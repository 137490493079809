import "../../styles/features/Table.scss";

import React, { useEffect, useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { LuChevronsLeftRight } from "react-icons/lu";
import {
  HiMiniArrowDown,
  HiMiniArrowUp,
  HiMiniArrowsUpDown,
} from "react-icons/hi2";

// *columns example from parent component - add this within the useEffect/getData GET for tableData prop
// setTableColumns([
//   { accessorKey: "jobNum", header: "Job Number" },
//   { accessorKey: "address1", header: "Address" },
// ]);

const DefaultCell = ({ getValue }) => <>{getValue()}</>;

export default function StandaloneTable({
  tableData,
  tableColumns,
  onRowClick,
  tableContainerType = "standaloneTableContainer",
}) {
  const [data, setData] = useState([]);
  const [error] = useState("");

  // useEffect(() => {
  //   console.log("data", data);
  //   console.log("tableColumns", tableColumns);
  // }, [data, tableColumns]);

  useEffect(() => {
    if (tableData) {
      setData(tableData);
    }
  }, [tableData]);

  const fullColumns = useMemo(
    () =>
      tableColumns.map((col) => ({
        accessorKey: col.accessorKey,
        header: col.header,
        cell: DefaultCell,
      })),
    [tableColumns]
  );

  const table = useReactTable({
    data: data,
    columns: fullColumns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    columnResizeMode: "onChange",
  });

  return (
    <div className={`${tableContainerType}`}>
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="headerRow" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const sortingState = header.column.getIsSorted(); // typically returns 'asc', 'desc', or false

                return (
                  <th
                    className="headerCell"
                    key={header.id}
                    style={{ width: header.getSize() }}
                  >
                    <div className="headerCellContent">
                      <div className="titleAndSort">
                        {sortingState === "asc" || sortingState === "desc" ? (
                          <div className="sortActive">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        ) : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}

                        <div className="sortArrow">
                          {header.column.getCanSort() && (
                            <>
                              {sortingState === "asc" ? (
                                <HiMiniArrowUp
                                  className="sortActive"
                                  size={17}
                                  onClick={header.column.getToggleSortingHandler()}
                                />
                              ) : sortingState === "desc" ? (
                                <HiMiniArrowDown
                                  className="sortActive"
                                  size={17}
                                  onClick={header.column.getToggleSortingHandler()}
                                />
                              ) : (
                                <HiMiniArrowsUpDown
                                  size={17}
                                  onClick={header.column.getToggleSortingHandler()}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {header.column.getCanResize() && (
                        // Resizing handle
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`}
                        >
                          <div
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className={`myResizer ${
                              header.column.getIsResizing() ? "isResizing" : ""
                            }`}
                          >
                            <LuChevronsLeftRight className="resizeHandle" />
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              className="tableRow"
              key={row.id}
              onClick={() => onRowClick && onRowClick(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  className="tableCell"
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p className="tableError">{error}</p>}
    </div>
  );
}
