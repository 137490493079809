import API_ENV from "@config";
import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

import CollapsibleGroup from "@ui/CollapsibleGroup";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    name: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewPartsCategory({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });
  // const [contentListData, setContentListData] = useState([]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  //* Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,

        // * BOOLEANS need || "" removed!
        name: stateData.name || "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/partsCategory/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL]
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Change Form", click: handleChangeForm },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Category Name"}
              name="name"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List 2"}
              name="field3"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Start Date</label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
