import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";

import CollapsibleGroup from "@ui/CollapsibleGroup";
import DocTagDisplay from "@ui/DocTagDisplay";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    description: yup.string().required("This field is required"),
    chargeable: yup.bool().required("This field is required"),
    jobCategoryRefId: yup.string().required("This field is required"),
    canBeSubJob: yup.bool().required("This field is required"),
    canHasSubJob: yup.bool().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewJobType({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });
  const [docTagData, setDocTagData] = useState([]);
  // const [contentListData, setContentListData] = useState([]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      chargeable: "",
      jobCategoryRefId: "",
      canBeSubJob: "",
      canHasSubJob: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,

        // * BOOLEANS need || "" removed!
        description: stateData.description || "",
        chargeable: stateData.chargeable,
        jobCategoryRefId: stateData.jobCategoryRefId || "",
        canBeSubJob: stateData.canBeSubJob,
        canHasSubJob: stateData.canHasSubJob,
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/jobCategory?showarchive=false`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(`${API_ENV}/documentTag?showarchive=false`, (data) => {
      console.log("documentTag data:", data);
      setDocTagData(data);
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("apiUrl", apiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Change Form", click: handleChangeForm },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  // useEffect(() => {
  //   // API GET contentList data
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log(data);
  //     setContentListData(data);
  //   });
  // }, []);

  // function targetClicked(event, item) {
  //   // Directly use the passed item object to update the form
  //   // No need to parse eventData from the event.target.getAttribute
  //   // console.log("targetClicked item: ", item);

  //   reset({
  //     // ID here is needed for PUT call in handleSubmitForm
  //     id: item?.id,
  //     // * BOOLEANS need || "" removed!
  //     // * Date values & others need to be null if there is no data!
  //     field1: item?.field1 || "",
  //   });
  // }

  return (
    <Form>
      {/* <div className="contentListContainer">
        <ContentList
          data={contentListData}
          type={"equipmentdetails"}
          clicked={targetClicked}
        />
      </div> */}

      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Description"}
              name="description"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Chargeable"}
              name="chargeable"
              control={control}
              errors={errors}
              dropdownData={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job Category"}
              name="jobCategoryRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Can Be Sub Job?"}
              name="canBeSubJob"
              control={control}
              errors={errors}
              dropdownData={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Can Have Sub Jobs?"}
              name="canHasSubJob"
              control={control}
              errors={errors}
              dropdownData={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      <CollapsibleGroup title="Required Documents">
        <DocTagDisplay data={docTagData} />
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
