import API_ENV from "@config";
import "../../styles/features/InfoDisplay.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import Hooks from "../../Hooks";
import Swal from "sweetalert2";
import { useTableData } from "../../context/TableDataContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HiOutlineEye,
  HiOutlineEyeSlash,
  HiOutlinePlusCircle,
} from "react-icons/hi2";

import Row from "../../ui/Row";
import Heading from "../../ui/Heading";
import Footer from "../../ui/Footer";
import PopupModal from "../generic/PopupModal/PopupModal";
import OperationsButtons from "./OperationsButtons";
import SidePanel from "../sidePanel/SidePanel";
import ReportOnlineHeader from "../header/reporting/ReportOnlineHeader";
import BasicFilter from "../filters/BasicFilter";
import PresetFilters from "../filters/PresetFilters";
import AdvancedFilter from "../filters/AdvancedFilter";
import Table from "../table/Table";
import ViewPresetFilter from "../filters/PresetFilterPopup/ViewPresetFilter";
import CreatePO from "../forms/PurchaseOrders/CreatePO";
import SubmittedPrList from "../forms/PurchaseRequests/SubmittedPrList";
import SearchResults from "../forms/PurchaseRequests/SearchResults";
import ValidatePo from "../forms/PurchaseOrders/ValidatePo";
import PartList from "../forms/SearchForPart/PartList";
import StockPopup from "../forms/Stock/StockPopup";

// Table functions.
export default function InfoDisplay({ setting }) {
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [liveButtonText, setLiveButtonText] = useState("Live");

  const [sidePanelState, setSidePanelState] = useState("");
  const [sidePanelData, setSidePanelData] = useState({});
  const [sidePanelDataObject, setSidePanelDataObject] = useState({});
  const [presetFilters, setPresetFilters] = useState(null);
  const [table, setTable] = useState();
  const [popupState, setPopupState] = useState();
  const [searchId, setSearchId] = useState();
  const [selectedPart, setSelectedPart] = useState();
  const [lastPage, setLastPage] = useState();

  const [selectedTableRowId, setSelectedTableRowId] = useState();
  const [initialTableURL, setInitialTableURL] = useState("");

  const pageTitle = useRef(null);
  const sidePanel = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [pageTitleText, setPageTitleText] = useState();
  // const pageTitleText = pageTitle.current.innerText;

  // const currentPage = location.pathname;
  // const pathSegments = currentPage.split("/");
  // let lastSegment = pathSegments[pathSegments.length - 1];

  const [isOpen, setIsOpen] = useState(true);
  const toggleHeader = () => setIsOpen(!isOpen);

  const { tableKeysData, tableContextFormData, headingToKeyMapping } =
    useTableData();

  const tableRef = useRef(null);
  const [isViewingArchived, setIsViewingArchived] = useState(false);

  // useEffect(() => {
  //   // console.log("sidePanelState:", sidePanelState);
  //   // console.log("setting:", setting);
  //   console.log("sidePanelData:", sidePanelData);
  //   // console.log("sidePanelDataObject:", sidePanelDataObject);
  //   // console.log("lastPage:", lastPage);
  //   // console.log("table:", table);
  //   // console.log("tableKeysData:", tableKeysData);
  //   // console.log("tableHeadings:", tableHeadings);
  //   // console.log("InfoDisplay.js - localTableState:", localTableState);
  //   // console.log("InfoDisplay.js - selectedTableRowId:", selectedTableRowId);
  //   // console.log("headingToKeyMapping in InfoDisplay:", headingToKeyMapping);
  //   // console.log("tableContextFormData in InfoDisplay:", tableContextFormData);
  //   // console.log("pageTitle:", pageTitle);
  //   // console.log("presetFilters:", presetFilters);
  // }, [sidePanelData]);

  function viewArchived() {
    setIsViewingArchived((prevState) => !prevState);
  }

  const refreshTable = useCallback(
    (forceLive = false) => {
      const isLiveData = forceLive ? true : !isViewingArchived;
      const urlModifier = isLiveData ? "" : "?showarchive=true";

      // Refresh the table with the appropriate URL
      if (tableRef.current) {
        tableRef.current.refreshData(urlModifier);
      }

      // If live data is being loaded, set the button text to "Live"
      if (isLiveData) {
        setLiveButtonText("Live");
      } else {
        setLiveButtonText("Archived");
      }
    },
    [isViewingArchived]
  );

  // Function to handle manual refresh (via button click)
  function handleRefreshClick() {
    refreshTable(true);
    setIsViewingArchived(false);
  }

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  // Routing URL settings
  useEffect(() => {
    setPageTitleText(pageTitle.current.innerText);
    setIsOpen(true);

    //setting last_page & navigation cookies
    const currentPage = location.pathname;
    Hooks.setCookie("last_page", setting, 1);
    Hooks.setCookie("navigation", currentPage, 1);

    // Fix for sidepanel flashing when clicking new table row on same page
    const pathSegments = currentPage.split("/");
    let lastSegment = pathSegments[pathSegments.length - 1];
    // console.log("lastSegment", lastSegment);

    // Remove the trailing slash and update the browser location
    if (location.pathname.endsWith("/")) {
      const newPath = location.pathname.slice(0, -1);
      window.location.replace(newPath);
    }

    // Remove '/undefined', "/null" or "/NaN" from the URL
    if (
      lastSegment === "undefined" ||
      lastSegment === "null" ||
      lastSegment === "NaN"
    ) {
      pathSegments.pop(); // Remove the last segment ("/undefined")
      lastSegment = pathSegments.join("/");
      navigate(lastSegment);
    }

    // if last segment is not a number, hide sidepanel
    if (isNaN(lastSegment)) {
      setShowSidePanel(false);
    }

    // if lastSegment IS a number setSelectedTableRowId as lastSegment as number value
    if (!isNaN(lastSegment)) {
      const lastSegmentNumber = Number(lastSegment);
      setSelectedTableRowId(lastSegmentNumber);
    }

    // sets lastPage to setting from routing via App.js
    setLastPage(() => setting);
  }, [setting, location.pathname, navigate]);

  function onClose() {
    setPopupState();
  }

  // Sets Routing for URL & calls tableRowClicked if there is a number after the last "/" so that it opens the table info on page load
  useEffect(() => {
    const currentPage = location.pathname;
    const pathSegments = currentPage.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    const lastSegmentNumber = Number(lastSegment);

    // if lastSegment IS a number call tableRowClicked with lastSegment as the tableRowId
    if (!isNaN(lastSegment)) {
      tableRowClicked(lastSegmentNumber);
    }

    // eslint-disable-next-line
  }, [selectedTableRowId]);

  // This is what fires off the API call to retrieve the data of a single row within a table.
  // Could this just not get the data without an additional API call by finding a matching ID?
  function tableRowClicked(tableRowId) {
    // console.log("tableRowClicked Triggered");
    // console.log(tableRowId);
    let apiUrl;

    if (!tableRowId) {
      try {
        const dataAttribute = sidePanel?.current?.getAttribute("data");
        if (dataAttribute) {
          tableRowId = JSON.parse(dataAttribute)?.id;
        }
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        console.error(
          "Invalid JSON data:",
          sidePanel?.current?.getAttribute("data")
        );
      }
    }

    // console.log("tableRowId", tableRowId);

    if (setting === "runreportasfileoremail") {
      apiUrl = `${API_ENV}/runReportProc/${tableRowId}`;
    } else if (setting === "partssubcategory1") {
      apiUrl = `${API_ENV}/${Hooks.resetKey(
        setting
      )}?Id=${tableRowId}&showarchive=false`;
    } else if (setting === "partssubcategory2") {
      apiUrl = `${API_ENV}/${Hooks.resetKey(
        setting
      )}?Id=${tableRowId}&showarchive=false`;
    } else if (setting === "partssubcategory3") {
      apiUrl = `${API_ENV}/${Hooks.resetKey(
        setting
      )}?Id=${tableRowId}&showarchive=false`;
    } else if (setting === "partssubcategory4") {
      apiUrl = `${API_ENV}/${Hooks.resetKey(
        setting
      )}?Id=${tableRowId}&showarchive=false`;
    } else {
      apiUrl = `${API_ENV}/${Hooks.resetKey(setting)}/${tableRowId}`;
    }

    const headers = new Headers({
      Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
    });

    // console.log("apiUrl", apiUrl);

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setSidePanelState(
          Hooks.conditionalString(
            document.querySelector("#titleContainerText").textContent
          )
        );

        // setSidePanelData is responsible for outputting data to:
        // SidePanel as "formState"
        // GenerateForm via SidePanel, as "state"
        // console.log("data", data);
        if (Array.isArray(data)) {
          setSidePanelData(JSON.stringify(data[0]));
          setSidePanelDataObject(data[0]);
        } else {
          setSidePanelData(JSON.stringify(data));
          setSidePanelDataObject(data);
        }
        // console.log("GET fetch Triggered");
        setShowSidePanel(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function sortClicked(target) {
    function getUrl(target) {
      let url;

      if (
        Hooks.getCookie("last_page") === "runreportasonlinecsvxls" ||
        Hooks.getCookie("last_page") === "runreportasonlinepdf"
      ) {
        url = `${API_ENV}/runReportList/runonline/runnow/${tableContextFormData.reportId}?Page=1&Size=100&SortColumn=`;
      } else if (Hooks.getCookie("last_page") === "runreportasfileoremail") {
        url = `${API_ENV}/runReportProc?RunByStaffId=${Hooks.getCookie(
          "userId"
        )}&Page=1&Size=100&SortColumnNum=`;
      } else {
        url = `${API_ENV}/${Hooks.getCookie(
          "last_page"
        )}?Page=1&Size=100&SortColumnNum=`;
      }

      let filter = "";

      if (
        document.querySelector(".filterSearch") &&
        document.querySelector(".filterSearch").getAttribute("filter")
      ) {
        filter =
          "&" + document.querySelector(".filterSearch").getAttribute("filter");
      }

      const direction = target.getAttribute("direction");

      const lowercasetableKeysData = tableKeysData.map((item) =>
        Hooks.conditionalString(item)
      );

      const sortOrders = {
        jobs: [
          "jobnumber",
          "client",
          "property",
          "contract",
          "reference",
          "engineer",
          "jobstate",
          "jobtype",
          "priority",
          "scheduleddate",
          "targetdate",
        ],
        contracts: [
          "name",
          "client",
          "startdate",
          "enddate",
          "contactname",
          "contactemail",
          "contactphone",
          "contactnotes",
        ],
        properties: [
          "selected",
          "uprn",
          "client",
          "address1",
          "address2",
          // "address3",
          // "address4",
          "town",
          "county",
          "postcode",
        ],
        purchaserequests: [
          "id",
          "partno",
          "quantity",
          "state",
          "targetdate",
          "requestedby",
          "requestdate",
          "targetjob",
        ],
        purchaseorders: [
          "id",
          "supplier",
          "state",
          "raisedby",
          "raiseddate",
          "targetdate",
        ],
        deliveries: [
          "deliveryid",
          "supplier",
          "delivery",
          "deliverynotenumber",
          "deliverynotestate",
          "deliverydate",
        ],
        clients: [
          "name",
          "address1",
          "address2",
          "address3",
          "address4",
          "town",
          "county",
          "postcode",
          "email",
          "phone",
        ],
        suppliers: [
          "name",
          "address1",
          "address2",
          "address3",
          "address4",
          "town",
          "county",
          "postcode",
          "email",
          "phone",
        ],
        parts: [
          "partnumber",
          "description",
          "category",
          "subcategory1",
          "subcategory2",
          "subcategory3",
          "subcategory4",
        ],
        partscategory: ["name"],
        partssubcategory1: ["name", "partscategory"],
        partssubcategory2: ["name", "partssubcategory1"],
        partssubcategory3: ["name", "partssubcategory2"],
        partssubcategory4: ["name", "partssubcategory3"],
        // Add entry here for table header sorting
        // Define Reports
        runreportlist: [
          "id",
          "reportname",
          "reporttype",
          "querytable",
          "filenameprefix",
          "apigroup",
        ],
        // Report Online
        // runreportasonlinelog: lowercasetableKeysData,
        // Report Online
        runreportasonlinecsvxls: lowercasetableKeysData,
        // Report Online
        runreportasonlinepdf: lowercasetableKeysData,
        // Run as File or Email
        runreportasfileoremail: [
          "processid",
          "reportname",
          "runby",
          "processstate",
          "processdate",
          "scheduledate",
          "format",
          "group",
          "emailflag",
          "recurflag",
        ],
        // View Report Process
        runreportproc: [
          "processid",
          "reportname",
          "runby",
          "processstate",
          "processdate",
          "scheduledate",
          "format",
          "group",
          "emailflag",
          "recurflag",
        ],
        // Custom Process
        runcustomprocess: [
          "processid",
          "reportname",
          "exportformat",
          "runby",
          "scheduletime",
          "actualstartdate",
          "sendemailto",
        ],
        // Scheduler Setup
        runschedulersetup: ["Test1", "Test2", "Test3"],
      };

      const type = Hooks.getCookie("last_page");
      // console.log("type:", type);
      // console.log("tableKeysData in sortClicked:", tableKeysData);
      // console.log("lowercasetableKeysData:", lowercasetableKeysData);
      // console.log("url:", url);

      if (!type || !sortOrders[type]) {
        console.log("no type found :(", "type:", type);
        return;
      }

      const tableHeadingString = Hooks.conditionalString(
        target.parentElement.textContent
      )
        .replace("🔽", "")
        .replace("🔼", "");

      sortOrders[type].forEach((item, index) => {
        if (
          tableHeadingString === item ||
          tableHeadingString === headingToKeyMapping[item]
        ) {
          if (
            type === "runreportasonlinecsvxls" ||
            type === "runreportasonlinepdf"
          ) {
            url = url + (index + 1) + "&SortOrder=" + direction + filter;
          } else {
            url = url + (index + 1) + "&SortByOrder=" + direction + filter;
          }
        }
      });
      // console.log("url:", url);
      // console.log("setting:", setting);

      return url;
    }

    setTable(
      <Table
        ref={tableRef}
        key={getUrl(target)}
        pageTitleDOM={pageTitle}
        sidePanel={sidePanel}
        rowClick={tableRowClicked}
        url={getUrl(target)}
        sortClicked={sortClicked}
        lastPage={lastPage}
        setSelectedTableRowId={setSelectedTableRowId}
        setShowSidePanel={setShowSidePanel}
      />
    );
  }

  function mainFilterSearch() {
    let url, searchValue;

    const searchTerm = Hooks.getFilterKey(
      document.querySelector("#searchInputDrop").value
    );

    // . Search Term Check here!
    // console.log("searchTerm: ", searchTerm);
    // console.log(
    //   `searchTerm non filtered: `,
    //   document.querySelector("#searchInputDrop").value
    // );

    const searchTerms = [
      "scheduled",
      "startdate",
      "targetdate",
      "deliverydatefrom",
    ];
    const filteredSearchTerm = Hooks.conditionalString(searchTerm);
    console.log("filteredSearchTerm", filteredSearchTerm);

    // Takes name from page title to use in API call like so ${API_ENV}/${apiName}
    let apiName = Hooks.getApiFromTitle(
      document.querySelector("#titleContainerText").textContent
    );

    // Some page titles don't match their API name on the back end, this switch corrects that
    switch (apiName) {
      case "definereports":
        apiName = "runReportlist";
        break;
      case "reportasfileoremail":
        apiName = "runReportProc";
        break;
      case "viewreportprocess":
        apiName = "runReportProc";
        break;
      case "viewletterprintprocess":
        apiName = "runcustomprocess";
        break;
      case "viewschedulerprocess":
        apiName = "runschedulersetup";
        break;
      case "inspectionsets":
        apiName = "inspectionset";
        break;

      default:
        break;
    }

    // console.log("apiName", apiName);

    if (
      searchTerms.some((term) =>
        Hooks.conditionalString(searchTerm).includes(term)
      )
    ) {
      let filterTerms = ["ScheduledFrom", "ScheduledUntil"];

      if (filteredSearchTerm.includes("raiseddate")) {
        filterTerms = ["OrderDateFrom", "OrderDateUntil"];
      } else if (filteredSearchTerm.includes("deliverydatefrom")) {
        filterTerms = ["DeliveryDateFrom", "DeliveryDateUntil"];
      } else if (filteredSearchTerm.includes("requestdate")) {
        filterTerms = ["RequestDateFrom", "RequestDateUntil"];
      } else if (filteredSearchTerm.includes("target")) {
        if (
          Hooks.getCookie("last_page") === "purchaserequests" ||
          Hooks.getCookie("last_page") === "purchaseorders"
        ) {
          filterTerms = ["TargetDateFrom", "TargetDateUntil"];
        } else {
          filterTerms = ["TargetFrom", "TargetUntil"];
        }
      } else if (
        filteredSearchTerm.includes("startdate") ||
        filteredSearchTerm.includes("starttime")
      ) {
        filterTerms = ["StartDateFrom", "EndDateUntil"];
      }

      const firstDate = new Date(
        document.querySelector("#basicSearchDate1").value
      ).toISOString();
      const secondDate = new Date(
        document.querySelector("#basicSearchDate2").value
      ).toISOString();

      url = `${API_ENV}/${apiName}?${filterTerms[0]}=${firstDate}&${filterTerms[1]}=${secondDate}`;
    } else if (
      ["jobs", "properties", "ticket"].includes(Hooks.getCookie("last_page"))
    ) {
      if (
        filteredSearchTerm.includes("target") ||
        filteredSearchTerm.includes("created")
      ) {
        if (filteredSearchTerm !== "createdby") {
          let filterTerms = [
            "TargetFrom",
            "TargetUntil",
            "CreatedFrom",
            "CreatedUntil",
          ];

          const firstDate = new Date(
            document.querySelector("#basicSearchDate1").value
          ).toISOString();
          const secondDate = new Date(
            document.querySelector("#basicSearchDate2").value
          ).toISOString();

          url = `${API_ENV}/${apiName}?${filterTerms[0]}=${firstDate}&${filterTerms[1]}=${secondDate}`;
        } else {
          searchValue = document.querySelector("#basicSearchQuery").value;
          url = `${API_ENV}/${apiName}?StartId=1&Page=1&Size=100&${searchTerm}=${searchValue}`;
        }
      } else {
        searchValue = document.querySelector("#basicSearchQuery").value;
        url = `${API_ENV}/${apiName}?StartId=1&Page=1&Size=100&${searchTerm}=${searchValue}`;
      }
    } else {
      searchValue = document.querySelector("#basicSearchQuery").value;

      if (Hooks.getCookie("last_page") === "runreportasfileoremail") {
        url = `${API_ENV}/${apiName}?${searchTerm}=${searchValue}&RunByStaffId=${Hooks.getCookie(
          "userId"
        )}`;
      } else if (Hooks.getCookie("last_page") === "partscategory") {
        url = `${API_ENV}/partsCategory?${searchTerm}=${searchValue}`;
      } else {
        url = `${API_ENV}/${apiName}?${searchTerm}=${searchValue}`;
      }
    }

    setShowSidePanel(false);

    console.log("1. mainFilterSearch URL: ", url);

    Hooks.getData(url, (data) => {
      setTable(
        <Table
          ref={tableRef}
          key={url}
          pageTitleDOM={pageTitle}
          sidePanel={sidePanel}
          rowClick={tableRowClicked}
          url={url}
          lastPage={lastPage}
          setSelectedTableRowId={setSelectedTableRowId}
          setShowSidePanel={setShowSidePanel}
          initialTableURL={initialTableURL}
        />
      );
    });
  }

  function addButtonClicked() {
    // console.log("addButtonClicked");

    // Assuming Hooks.conditionalString returns a string
    const pageType = Hooks.conditionalString(
      document.querySelector("#titleContainerText").textContent
    );

    setSidePanelState("add" + pageType);
    setShowSidePanel(true);

    // Removes the "/:tableRowId?" from the URL on sidePanel close
    // const currentPage = location.pathname;
    // const newPath = currentPage.replace(/\/\d+$/, ""); // Removes the last numeric segment
    // navigate(newPath);
  }

  function advancedFilter() {
    setPopupState(
      <AdvancedFilter onSearch={afSearchClicked} onClose={onClose} />
    );
  }

  function afSearchClicked() {
    const filterRows = document.querySelectorAll(".filterRow");
    if (!filterRows || !filterRows.length) {
      console.error("Could not find filter rows.");
      return;
    }

    let url = `${API_ENV}/jobs?`;
    const formatKey = {
      contract: "contractId",
      jobtype: "jobTypeRefId",
      jobstatus: "jobStatusRefId",
      reference: "Reference",
      engineer: "EngineerIds",
      general: "GeneralSearch",
    };

    filterRows.forEach((row) => {
      const key = Hooks.conditionalString(
        row.querySelector(".filterRowType").value
      );
      const formattedKey = formatKey[key] || key;
      const value = row.querySelector(".filterRowInput").value;
      url = url + `${formattedKey}=${value}&`;
    });

    // If last character is "&", then remove it from the url.
    if (url.charAt(url.length - 1) === "&") {
      url = url.substring(0, url.length - 1);
    }

    setTable(
      <Table
        ref={tableRef}
        key={url}
        pageTitleDOM={pageTitle}
        sidePanel={sidePanel}
        rowClick={tableRowClicked}
        url={url}
        lastPage={lastPage}
        setSelectedTableRowId={setSelectedTableRowId}
        setShowSidePanel={setShowSidePanel}
      />
    );
    setPopupState();
  }

  function stockView(target) {
    if (!target) {
      return;
    }
    const mode = Hooks.conditionalString(target.textContent);
    if (mode === "warehouse") {
      document.querySelector("#warehouseButton .headerButtonText").textContent =
        "Van";
      setTable(
        <Table
          ref={tableRef}
          key="vanTable"
          type="stockvan"
          pageTitle="stockVan"
          pageTitleDOM={pageTitle}
          sidePanel={sidePanel}
          rowClick={tableRowClicked}
          sortClicked={sortClicked}
          setSelectedTableRowId={setSelectedTableRowId}
          setShowSidePanel={setShowSidePanel}
        />
      );
    } else if (mode === "van") {
      document.querySelector("#warehouseButton .headerButtonText").textContent =
        "Warehouse";
      setTable(
        <Table
          ref={tableRef}
          key="warehouseTable"
          type="stockwarehouse"
          pageTitle="stockWarehouse"
          pageTitleDOM={pageTitle}
          sidePanel={sidePanel}
          rowClick={tableRowClicked}
          sortClicked={sortClicked}
          lastPage={lastPage}
          setSelectedTableRowId={setSelectedTableRowId}
          setShowSidePanel={setShowSidePanel}
        />
      );
    }
  }

  function loadHeader() {
    const validSettings = ["processes", "stockmovements"];
    if (
      validSettings.includes(setting) ||
      (setting === "certifications" &&
        pageTitle.current &&
        Hooks.conditionalString(pageTitle.current.textContent) !== "staff")
    ) {
      return (
        <OperationsButtons
          buttons={[
            { text: "Refresh", onClick: handleRefreshClick },
            { text: liveButtonText, onClick: viewArchived },
          ]}
        />
      );
    } else if (setting === "stock") {
      return (
        <OperationsButtons
          buttons={[
            { text: "Add", onClick: addButtonClicked },
            { text: "Refresh", onClick: handleRefreshClick },
            { text: liveButtonText, onClick: viewArchived },
            {
              text: "Warehouse",
              onClick: (e) => {
                stockView(e.target);
              },
            },
          ]}
        />
      );
    } else if (setting === "jobs") {
      return (
        <OperationsButtons
          buttons={[
            { text: "Add", onClick: addButtonClicked },
            { text: "Refresh", onClick: handleRefreshClick },
            { text: liveButtonText, onClick: viewArchived },
            { text: "Filter", onClick: advancedFilter },
          ]}
        />
      );
    } else if (
      setting === "runreportasonlinecsvxls" ||
      setting === "runreportasonlinepdf"
    ) {
      return <ReportOnlineHeader setTableData={setTable} />;
    } else {
      return (
        <OperationsButtons
          buttons={[
            { text: "Add", onClick: addButtonClicked },
            { text: "Refresh", onClick: handleRefreshClick },
            { text: liveButtonText, onClick: viewArchived },
          ]}
        />
      );
    }
  }

  function backClick() {
    Hooks.getData(`${API_ENV}/purchaseRequest?prStateId=2`, (data) => {
      setPopupState(
        <SubmittedPrList
          changeView={changeView}
          type="prlist"
          confirm={confirmPurchaseRequests}
          data={data}
          onClose={onClose}
        />
      );
    });
  }

  function changeSelection(prList) {
    Hooks.getData(`${API_ENV}/purchaseRequest?prStateId=2`, (data) => {
      setPopupState(
        <SubmittedPrList
          changeView={changeView}
          type="prlist"
          confirm={confirmPurchaseRequests}
          data={data}
          onClose={onClose}
          selected={prList}
        />
      );
    });
  }

  // HERE IS THE PART SEARCH MODAL OPEN!
  function partSearch(data, firstPart) {
    // Part category search.
    if (data && data.length && data[0]?.name) {
      setPopupState(
        <PartList
          content={data}
          select={selectPart}
          onClose={onClose}
          type="partcategory"
          leaf={firstPart}
        />
      );
      return;
    }

    function selectPart(part) {
      if (!part || !part?.partno) {
        console.error("There was an error selecting the part.");
        return;
      }

      setSelectedPart({ partId: part?.id, partNo: part?.partno });
      onClose();
    }

    setPopupState(
      <PartList
        content={data}
        select={selectPart}
        onClose={onClose}
        leaf={firstPart}
      />
    );
  }

  function confirmPurchaseRequests(type, submittedIds) {
    if (!submittedIds || !submittedIds.length) {
      Hooks.displayError("Please select one or more option.");
      return;
    }
    const api = type === "createpo" ? "purchaseRequest" : "poDetail";

    const prefixedIds = submittedIds.map((id) => "Ids=" + id);

    Hooks.getData(`${API_ENV}/${api}?${prefixedIds.join("&")}`, (data) => {
      setPopupState(
        <CreatePO
          type={type}
          data={data}
          backClick={backClick}
          onClose={onClose}
          changeSelection={changeSelection}
        />
      );
    });
  }

  useEffect(() => {
    function presetSearch() {
      const presetFilterId = document.querySelector(
        "#presetFilterSelect"
      ).value;
      if (presetFilterId) {
        fetch(`${API_ENV}/jobfilter/${presetFilterId}`)
          .then((response) => response.json())
          .then((data) => {
            let url = `${API_ENV}/jobs?`;
            for (let searchTerm in data[0].contents) {
              const key = Hooks.getFilterKey(searchTerm);
              const value = data[0].contents[searchTerm];
              url = url + key + "=" + value + "&";
            }

            // Remove last & symbol from string.
            url = url.slice(0, -1);
            setTable(
              <Table
                ref={tableRef}
                key={url}
                pageTitleDOM={pageTitle}
                sidePanel={sidePanel}
                type=""
                rowClick={tableRowClicked}
                url={url}
                sortClicked={sortClicked}
                lastPage={lastPage}
                setSelectedTableRowId={setSelectedTableRowId}
                setShowSidePanel={setShowSidePanel}
              />
            );
          });
      }
    }

    function createPurchaseOrder() {
      Hooks.getData(`${API_ENV}/purchaseRequest?prStateId=2`, (data) => {
        setPopupState(
          <SubmittedPrList
            changeView={changeView}
            type="prlist"
            confirm={confirmPurchaseRequests}
            data={data}
            onClose={onClose}
          />
        );
      });
    }

    function presetView() {
      const id = parseInt(document.querySelector("#presetFilterSelect").value);
      if (id) {
        setPopupState(<ViewPresetFilter filterId={id} onClose={onClose} />);
      } else {
        Hooks.displayError("Please select a contract from the list.");
      }
    }

    // add new conditional for page to show saved filters
    if (setting === "jobs") {
      setPresetFilters(
        <PresetFilters onSearch={presetSearch} onView={presetView} />
      );
    } else if (setting === "purchaserequests") {
      setPresetFilters(
        <div id="presetFilterContainer">
          <div id="addPoButton" onClick={createPurchaseOrder}>
            <HiOutlinePlusCircle />
            Create Purchase Order
          </div>
        </div>
      );
    } else {
      setPresetFilters(null);
    }

    // * Initial URL settings
    const getInitialUrl = () => {
      switch (setting) {
        case "archive":
          const urlType = Hooks.conditionalString(pageTitle);

          if (urlType === "definereports") {
            break;
          }

          return `${API_ENV}/${Hooks.resetKey(urlType)}/archive`;

        case "stock":
          return `${API_ENV}/stock?isVan=false`;

        case "stockvan":
          return `${API_ENV}/stock?isVan=true`;

        case "stockwarehouse":
          return `${API_ENV}/stock?isVan=false`;

        case "properties":
          return `${API_ENV}/${setting}?hideSubProperty=true`;

        case "jobs":
          return `${API_ENV}/${setting}?hideSubJobs=true`;

        case "parts":
          return `${API_ENV}/parts?limit=100&showarchive=false`;

        // Add pages with manual/dynamic tables here
        // case "runreportasonlinelog":
        case "runreportasonlinecsvxls":
        case "runreportasonlinepdf":
          break;
        case "runreportasfileoremail":
          return `${API_ENV}/runReportProc?RunByStaffId=${Hooks.getCookie(
            "userId"
          )}`;

        case "runcustomprocess":
          return `${API_ENV}/LetterPrintProcess?showarchive=false`;

        default:
          return `${API_ENV}/${Hooks.resetKey(setting)}`;
      }
    };

    const url = getInitialUrl();
    setInitialTableURL(url); // Store the initial URL
    // console.log("url", url);

    // * DEFAULT TABLE
    setTable(
      <Table
        ref={tableRef}
        key={setting}
        type={setting}
        pageTitleDOM={pageTitle}
        sidePanel={sidePanel}
        sortClicked={sortClicked}
        lastPage={lastPage}
        tableRowClicked={tableRowClicked}
        setSelectedTableRowId={setSelectedTableRowId}
        setShowSidePanel={setShowSidePanel}
        initialTableURL={url}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  // This should be state
  function itemClicked(data, type, target) {
    switch (type) {
      case "parts":
        target.value = data.partno;
        target.setAttribute("dataid", data?.id);
        break;
      case "targetjob":
        // Todo improve this.
        let selector = document.querySelector("#targetJobSearch");
        if (!selector && document.querySelector("#jobSearch")) {
          selector = document.querySelector("#jobSearch");
        }

        if (!selector) {
          selector = document.querySelector("#targetjobSearch");
        }

        selector.value = data?.id;
        selector.setAttribute("dataId", data?.id);
        break;
      case "supplierparts":
        document.querySelector("#searchForPart").value = data?.parts?.partno;
        document
          .querySelector("#searchForPart")
          .setAttribute("dataid", data?.parts?.id);
        setSearchId(data?.parts?.id);
        break;
      case "stocklocation":
        document.querySelector(
          "#stocklocationSearch"
        ).value = `Aisle: ${data?.aisle} => Rack: ${data?.rack} => Level: ${data?.level}`;
        document
          .querySelector("#stocklocationSearch")
          .setAttribute("dataid", data?.id);
        break;
      default:
        break;
    }

    onClose();
  }

  function sidePanelSearchResults(target, type) {
    const value = target.value;

    let url = `${API_ENV}/`;
    switch (type) {
      case "parts":
        url += `parts?PartNo=${value}`;
        break;
      case "stocklocation":
        url += `stockLocation?Aisle=${value}`;
        break;
      case "targetjob":
        url += `jobs?IdOrAddress=${value}`;
        break;
      case "targetwarehouse":
        url += `warehouses?Name=${value}`;
        break;
      case "targetlocation":
        url += `properties?Address1=${value}`;
        break;
      case "targetapprover":
        url += `staff?ForeName=${value}`;
        break;
      case "supplierparts":
        const partValue = document.querySelector("#searchForPart").value;
        url += `partsAndSupplier?PartsNoOrDesc=${partValue}`;
        break;
      default:
        break;
    }

    Hooks.getData(url, (data) => {
      setPopupState(
        <SearchResults
          target={target}
          itemClicked={itemClicked}
          results={data}
          type={type}
          onClose={onClose}
        />
      );
    });
  }

  function stockAllocation() {
    const warehouseId = Hooks.getSidePanelData().targetWarehouseId;

    function stockClicked(item) {
      const stockLocationId = item.id;
      const purchaseReqId = Hooks.getSidePanelData().id;
      const url = `${API_ENV}/purchaseRequest/bulbtoStockAllocation/${warehouseId}/${stockLocationId}/${purchaseReqId}`;
      Hooks.sendData({}, url, "PUT", (response) => {
        if (response.ok) {
          onClose();
        }
      });
    }

    Hooks.getData(
      `${API_ENV}/stockLocation?WarehouseID=${warehouseId}`,
      (data) => {
        if (!data) {
          return;
        }
        setPopupState(
          <SearchResults
            itemClicked={stockClicked}
            results={data}
            type={"stocklocation"}
            onClose={onClose}
          />
        );
      }
    );
  }

  function changeView(dropdown) {
    if (!dropdown || !dropdown.current || !dropdown.current.value) {
      console.error("An error occured loading the dropdown.");
      return;
    }

    const value = Hooks.conditionalString(dropdown.current.value);

    if (value === "submittedprs") {
      Hooks.getData(`${API_ENV}/purchaseRequest?prStateId=2`, (data) => {
        setPopupState(
          <SubmittedPrList
            changeView={changeView}
            type="prlist"
            confirm={confirmPurchaseRequests}
            data={data}
            onClose={onClose}
          />
        );
      });
    } else {
      Hooks.getData(`${API_ENV}/purchaseRequest`, (data) => {
        setPopupState(
          <SubmittedPrList
            changeView={changeView}
            type="prlist"
            confirm={confirmPurchaseRequests}
            data={data}
            onClose={onClose}
          />
        );
      });
    }
  }

  function validateSet(type) {
    const id = Hooks.getSidePanelData()?.id;

    if (!id) {
      return;
    }
    let url;

    if (type === "po") {
      url = `${API_ENV}/purchaseOrder/validate/${id}`;
    } else if (type === "importstock") {
      url = `${API_ENV}/deliveryNote/import/${id}?DirectAllocateToStock=${
        document.querySelector("#directlyallocateInput").checked
      }`;
    } else {
      url = `${API_ENV}/deliveryNote/validate/${id}`;
    }

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.showMessageToUser) {
          Swal.fire({
            title: "Validation Complete",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              setPopupState(
                <ValidatePo type={type} data={data} onClose={onClose} />
              );
            }
          });
        } else {
          if (typeof data === "string") {
            Hooks.displayError(data);
          } else {
            Hooks.displayError("An error has occured with this validation.");
          }
        }
      });
  }

  function showStockPopup(type) {
    setPopupState(<StockPopup type={type} closeClicked={onClose} />);
  }

  function createDeliveryOrder() {
    const purchaseOrderId = Hooks.getSidePanelData()?.id;
    if (!purchaseOrderId) {
      return;
    }

    Hooks.getData(
      `${API_ENV}/poDetail?PurchaseOrderId=${purchaseOrderId}`,
      (poDetails) => {
        setPopupState(
          <SubmittedPrList
            changeView={changeView}
            type="createdelivery"
            data={poDetails}
            confirm={() => {
              confirmPurchaseRequests("createdelivery");
            }}
            onClose={onClose}
          />
        );
      }
    );
  }

  return (
    <div id="infoDisplayContainer" className="infoDisplayContainer">
      <PopupModal onClose={onClose}>{popupState}</PopupModal>

      <SidePanel
        type={sidePanelState}
        ref={sidePanel}
        data={sidePanelData}
        sidePanelDataObject={sidePanelDataObject}
        refreshTable={refreshTable}
        addButtonClicked={addButtonClicked}
        search={sidePanelSearchResults}
        pageTitle={pageTitle}
        stockAllocation={stockAllocation}
        searchId={searchId}
        validateSet={validateSet}
        createDeliveryOrder={createDeliveryOrder}
        showStockPopup={showStockPopup}
        partSearch={partSearch}
        selectedPart={selectedPart}
        updatePopup={setPopupState}
        // fetchSingleRow={fetchSingleRow}
        showSidePanel={showSidePanel}
        setShowSidePanel={setShowSidePanel}
      />

      <Row type="horizontal" id="infoDisplayHeaderTitle" ref={pageTitle}>
        <Heading as="h1" id="titleContainerText">
          {Hooks.formatKey(Hooks.conditionalString(setting))}
        </Heading>
      </Row>

      <div id="pageOperationsContainer">
        {pageTitleText === "Report Online CSV XLS" ||
        pageTitleText === "Report Online PDF" ? (
          <div className={`headerToggle`} onClick={toggleHeader}>
            {isOpen ? (
              <HiOutlineEye size={20} />
            ) : (
              <HiOutlineEyeSlash size={20} />
            )}
          </div>
        ) : null}

        <div className={`contentWrapper ${isOpen ? "open" : "closed"}`}>
          {/* This is OperationsButtons.js */}
          {loadHeader()}

          {pageTitleText !== "Report Online CSV XLS" &&
          pageTitleText !== "Report Online PDF" ? (
            <BasicFilter
              key="basicFilter"
              type={setting}
              onSearch={(element) => {
                mainFilterSearch(element.target);
              }}
            />
          ) : null}

          {presetFilters && (
            <div id="additionalSearchContainer">{presetFilters}</div>
          )}
        </div>
      </div>

      {/* <TableHeader tableHeadings={tableHeadings} sortClicked={sortClicked} /> */}

      {table}

      <Footer />
    </div>
  );
}
