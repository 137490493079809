import React from "react";

const SidePanelTabs = ({ tabs, currentTabIndex }) => {
  const handleTabClick = (index, clickHandler, element) => {
    clickHandler(element, index); // Pass the element to the click function (tabClicked() in SidePanel.js)
  };

  return (
    <div className="tabContainer">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`tab ${index === currentTabIndex ? "currentTab" : ""}`}
          onClick={(e) => handleTabClick(index, tab.click, e.target)}
        >
          {tab.text}
        </div>
      ))}
    </div>
  );
};

export default SidePanelTabs;
