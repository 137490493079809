import API_ENV from "@config";
import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import AddressInput from "features/forms/AddressInput";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    address1: yup.string().required("This field is required"),
    town: yup.string().required("This field is required"),
    county: yup.string().required("This field is required"),
    postCode: yup
      .string()
      .required("This field is required")
      .matches(
        /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i,
        "Invalid UK postcode"
      ),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddSupplier({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      town: "",
      county: "",
      postCode: "",
      phone: "",
    },
  });

  // const { openModal } = useModal();

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      // console.log("apiUrl", apiUrl);
      // console.log("modifiedApiUrl", modifiedApiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        "POST",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Name"}
              name="name"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Email"}
              name="email"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <AddressInput register={register} errors={errors} readOnly={false} />

          {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List 2"}
              name="field3"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <label>Start Date</label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
