import "../styles/pages/Login.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Heading from "../ui/Heading";
import Button from "../ui/Button";
import DarkModeToggle from "../ui/DarkModeToggle";
import InputOldNonRHF from "../ui/InputOldNonRHF";

const initialFormData = {
  username: "",
  password: "",
  twoFAMethod: "",
  twoFACode: "",
  email: "",
  passwordResetOTPCode: "",
  newPassword1: "",
  newPassword2: "",
};

export default function Login() {
  const {
    login,
    twoFAMethod,
    twoFALogin,
    twoFAData,
    setTwoFAData,
    obfuscateEmail,
    obfuscatePhone,
    isLoading,
    checkEmail,
    forgotPasswordOTP,
    resetPassword,
  } = useAuth();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState(initialFormData);

  const handleLogin = async () => {
    try {
      setErrorMessage(null);
      await login({
        username: formData.username,
        password: formData.password,
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handle2FAMethod = async (resendFlag) => {
    try {
      setErrorMessage(null);
      await twoFAMethod(
        {
          username: formData.username,
          oneTimeCode: "",
          sendMethod: formData.twoFAMethod,
        },
        resendFlag
      );
      setTwoFAData((prevData) => ({
        ...prevData,
        twoFARadioSelect: false,
      }));
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handle2FALogin = async () => {
    try {
      setErrorMessage(null);
      await twoFALogin({
        username: formData.username,
        oneTimeCode: formData.twoFACode,
        sendMethod: formData.twoFAMethod,
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  function handleForgotPasswordScreen() {
    setTwoFAData((prevData) => ({
      ...prevData,
      forgotPassword: !prevData.forgotPassword,
      forgotPasswordScreen: "1",
    }));
  }

  const handleEmailCheck = async () => {
    try {
      setErrorMessage(null);
      await checkEmail({
        email: formData.email,
      });
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handlePasswordOTP = async () => {
    try {
      setErrorMessage(null);
      await forgotPasswordOTP({
        username: twoFAData.passwordResetData.username,
        oneTimeCode: formData.passwordResetOTPCode,
        sendMethod: "e",
      });
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handleNewPasswordConfirm = async () => {
    const password = formData.newPassword1;

    // Regex to check password complexity
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,20}$/;

    if (password !== formData.newPassword2) {
      setErrorMessage("The passwords do not match, please try again.");
      return;
    }

    if (!passwordRegex.test(password)) {
      setErrorMessage(
        "Password must be 8-20 characters long, include at least 1 uppercase, 1 lowercase letter, and 1 number."
      );
      return;
    }

    try {
      setErrorMessage(null);
      await resetPassword({
        username: twoFAData.passwordResetData.username,
        newPassword: password,
      });
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <>
      <div className="loginWrapper">
        <div className="loginToggleHeader">
          <div className="loginDarkModeToggle">
            <DarkModeToggle />
          </div>
        </div>

        <div className="loginForm">
          {/*//. 1st Login Screen: Normal Login & Forgot Password */}
          {twoFAData.primaryLogin && (
            <>
              {/*//* Normal Login */}
              {!twoFAData.forgotPassword && (
                <>
                  <div className="loginHeader">
                    <Heading as="h1">User Login</Heading>
                    <Heading as="h4">Please enter username & password.</Heading>
                  </div>

                  <div className="loginInputContainer">
                    <InputOldNonRHF
                      label="username"
                      name="username"
                      showLabel={false}
                      size="large"
                      placeholder="Username"
                      autoComplete="on"
                      maxLength="20"
                      formData={formData}
                      setFormData={setFormData}
                    />

                    <InputOldNonRHF
                      label={"password"}
                      name={"password"}
                      showLabel={false}
                      size="large"
                      type="password"
                      placeholder="Password"
                      autoComplete="on"
                      maxLength="20"
                      formData={formData}
                      setFormData={setFormData}
                      onKeyDown={(e) => {
                        if (e.key.toLowerCase() === "enter") {
                          handleLogin();
                        }
                      }}
                    />
                  </div>

                  {errorMessage && (
                    <p className="loginError">Error: {errorMessage}</p>
                  )}

                  <p
                    className="forgotPassword"
                    onClick={handleForgotPasswordScreen}
                  >
                    Forgot your password?
                  </p>

                  <Button
                    size={"large"}
                    onClick={handleLogin}
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </>
              )}

              {/*//* Forgot Password */}
              {twoFAData.forgotPassword && (
                <>
                  {/*//* Password Reset Screen 1: Email Input */}
                  {twoFAData.forgotPasswordScreen === "1" && (
                    <>
                      <div className="loginHeader">
                        <Heading as="h1">Password Reset</Heading>
                        <Heading as="h4">
                          Please enter your email address to receive a One-Time
                          Passcode to reset your password.
                        </Heading>
                      </div>

                      <div className="loginInputContainer">
                        <InputOldNonRHF
                          label="email"
                          name="email"
                          showLabel={false}
                          size="large"
                          placeholder="Email"
                          autoComplete="on"
                          formData={formData}
                          setFormData={setFormData}
                        />
                      </div>

                      {errorMessage && (
                        <p className="loginError">Error: {errorMessage}</p>
                      )}

                      <Button
                        size={"large"}
                        onClick={handleEmailCheck}
                        disabled={isLoading}
                      >
                        Submit Email
                      </Button>
                    </>
                  )}

                  {/*//* Password Reset Screen 2: Code Entry */}
                  {twoFAData.forgotPasswordScreen === "2" && (
                    <>
                      <div className="loginHeader">
                        <Heading as="h1">Password Reset</Heading>
                        <Heading as="h4">
                          Your One-Time Passcode has been sent via email. Please
                          enter your One-Time Passcode.
                        </Heading>
                      </div>

                      <div className="userInfo">
                        <p>Username: {twoFAData.passwordResetData.username}</p>
                        <p>Email: {twoFAData.passwordResetData.email}</p>
                      </div>

                      <div className="loginInputContainer">
                        <div className="twoFAColumn">
                          <label htmlFor="twoFACode">Code Entry:</label>

                          <input
                            className="twoFACodeInputField"
                            type="text"
                            name="passwordResetOTPCode"
                            maxLength="4"
                            pattern="[0-9]*" // Allows only numeric input
                            value={formData.passwordResetOTPCode}
                            onChange={(e) => {
                              // Regular expression to allow only numeric input
                              const onlyNumbers = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              // Limit the length to 4
                              const truncatedValue = onlyNumbers.slice(0, 4);

                              // Update the state with the sanitized value
                              setFormData((prevData) => ({
                                ...prevData,
                                passwordResetOTPCode: truncatedValue,
                              }));
                            }}
                            onKeyDown={(event) => {
                              if (event.key.toLowerCase() === "enter") {
                                handlePasswordOTP();
                              }
                            }}
                          />
                        </div>
                      </div>

                      {errorMessage && (
                        <p className="loginError">Error: {errorMessage}</p>
                      )}

                      <Button
                        size={"large"}
                        onClick={handlePasswordOTP}
                        disabled={isLoading}
                      >
                        Submit Code
                      </Button>

                      <p>Need another code?</p>

                      <Button
                        size={"large"}
                        variation={"secondary"}
                        disabled={isLoading}
                        onClick={() => {
                          setTwoFAData((prevData) => ({
                            ...prevData,
                            forgotPasswordScreen: "1",
                          }));
                        }}
                      >
                        Request another 2FA code
                      </Button>
                    </>
                  )}
                  {/*//* Password Reset Screen 3: Entering New Password */}
                  {twoFAData.forgotPasswordScreen === "3" && (
                    <>
                      <div className="loginHeader">
                        <Heading as="h1">Password Reset</Heading>
                        <Heading as="h4">
                          Please enter a new password in both fields to confirm
                          your new password.
                        </Heading>
                      </div>

                      <div className="userInfo">
                        <ul>
                          <p>Password requirements:</p>
                          <li>Password length 8-20 characters</li>
                          <li>1 Uppercase, 1 Lowercase & 1 Number</li>
                        </ul>
                      </div>

                      <div className="loginHeader">
                        {/* <p>Username: {twoFAData.passwordResetData.username}</p> */}
                      </div>

                      <div className="loginInputContainer">
                        <InputOldNonRHF
                          label={"newPassword1"}
                          name={"newPassword1"}
                          showLabel={false}
                          size="large"
                          type="password"
                          placeholder="Enter new password"
                          autoComplete="on"
                          maxLength="20"
                          formData={formData}
                          setFormData={setFormData}
                        />

                        <InputOldNonRHF
                          label={"newPassword2"}
                          name={"newPassword2"}
                          showLabel={false}
                          size="large"
                          type="password"
                          placeholder="Confirm new password"
                          autoComplete="on"
                          maxLength="20"
                          formData={formData}
                          setFormData={setFormData}
                          onKeyDown={(e) => {
                            if (e.key.toLowerCase() === "enter") {
                              handleNewPasswordConfirm();
                            }
                          }}
                        />
                      </div>

                      {errorMessage && (
                        <p className="loginError">Error: {errorMessage}</p>
                      )}

                      <Button
                        size={"large"}
                        onClick={handleNewPasswordConfirm}
                        disabled={isLoading}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                  {/*//* Password Reset Screen 4: Password Reset Success */}
                  {twoFAData.forgotPasswordScreen === "4" && (
                    <>
                      <div className="loginHeader">
                        <Heading as="h1">Password Reset</Heading>
                        <Heading as="h4">
                          Your password was successfully reset. Please return to
                          the login page.
                        </Heading>
                      </div>
                    </>
                  )}
                  <Button
                    size={"large"}
                    type={"secondary"}
                    onClick={() => {
                      setTwoFAData((prevData) => ({
                        ...prevData,
                        forgotPassword: false,
                      }));

                      setFormData(initialFormData);
                    }}
                  >
                    Return to user login.
                  </Button>
                </>
              )}
            </>
          )}

          {/*//. 2nd Login Screen: 2FA Method selection & Code Entry */}
          {!twoFAData.primaryLogin && (
            <>
              {/*//* One Time Password: Method Selection */}
              {twoFAData.twoFARadioSelect && (
                <>
                  <div className="loginHeader">
                    <Heading as="h1">One-Time Passcode Required</Heading>
                    <Heading as="h4">
                      Please select an option to recieve your one-time passcode.
                    </Heading>
                  </div>

                  <div className="twoFAMethodContainer">
                    <div className="twoFAMethodRow">
                      <label htmlFor="email2FA">Via Email:</label>

                      <input
                        id="email2FA"
                        type="radio"
                        name="email2FAOption"
                        value="e"
                        checked={formData.twoFAMethod === "e"}
                        onChange={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFAMethod: "e",
                          }));
                          setTwoFAData((prevData) => ({
                            ...prevData,
                            emailObfuscate: true,
                          }));
                        }}
                      />
                    </div>

                    <div className="twoFAMethodRow">
                      <label htmlFor="phone2FA">Via Phone (SMS):</label>

                      <input
                        id="phone2FA"
                        type="radio"
                        name="phone2FAOption"
                        value="p"
                        checked={formData.twoFAMethod === "p"}
                        onChange={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFAMethod: "p",
                          }));
                          setTwoFAData((prevData) => ({
                            ...prevData,
                            emailObfuscate: false,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="error">Error: {errorMessage}</div>
                  )}

                  {!twoFAData.first2FASent ? (
                    <Button
                      disabled={isLoading}
                      size={"large"}
                      onClick={() => {
                        handle2FAMethod("N");
                      }}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      size={"large"}
                      onClick={() => {
                        handle2FAMethod("Y");
                      }}
                    >
                      Continue
                    </Button>
                  )}
                </>
              )}

              {/*//* One Time Password: Code Entry */}
              {!twoFAData.twoFARadioSelect && (
                <>
                  <div className="loginHeader">
                    <Heading as="h1">One-Time Passcode Required</Heading>

                    {twoFAData.emailObfuscate ? (
                      <Heading as="h4">
                        An email has been sent to:{" "}
                        {obfuscateEmail(twoFAData.loginData.email)}
                      </Heading>
                    ) : (
                      <Heading as="h4">
                        An SMS message has been sent to:{" "}
                        {obfuscatePhone(twoFAData.loginData.mobile)}
                      </Heading>
                    )}

                    <Heading as="h4">
                      Please enter your one-time passcode
                    </Heading>
                  </div>

                  <div id="twoFACodeContainer">
                    <div className="twoFAColumn">
                      <label htmlFor="twoFACode">Code Entry:</label>

                      <input
                        className="twoFACodeInputField"
                        type="text"
                        name="twoFACode"
                        maxLength="4"
                        pattern="[0-9]*" // Allows only numeric input
                        value={formData.twoFACode}
                        onChange={(e) => {
                          // Regular expression to allow only numeric input
                          const onlyNumbers = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );

                          // Limit the length to 4
                          const truncatedValue = onlyNumbers.slice(0, 4);

                          // Update the state with the sanitized value
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFACode: truncatedValue,
                          }));
                        }}
                        onKeyDown={(event) => {
                          if (event.key.toLowerCase() === "enter") {
                            handle2FALogin();
                          }
                        }}
                      />
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="error">Error: {errorMessage}</div>
                  )}

                  <div className="twoFALoginButtons">
                    <Button
                      size={"large"}
                      disabled={isLoading}
                      onClick={() => handle2FALogin()}
                    >
                      Submit Code
                    </Button>

                    <p>Need another code?</p>

                    <Button
                      size={"large"}
                      variation={"secondary"}
                      disabled={isLoading}
                      onClick={() => {
                        setTwoFAData((prevData) => ({
                          ...prevData,
                          twoFARadioSelect: true,
                        }));
                      }}
                    >
                      Request another 2FA code
                    </Button>
                  </div>
                </>
              )}

              <Button
                size={"large"}
                type={"secondary"}
                onClick={() => {
                  setTwoFAData((prevData) => ({
                    ...prevData,
                    primaryLogin: true,
                  }));

                  setFormData(initialFormData);
                }}
              >
                Return to user login.
              </Button>
            </>
          )}
          {/* {errorMessage ? <div className="error">Error: {errorMessage}</div> : null} */}
        </div>
      </div>
    </>
  );
}
