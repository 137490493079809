import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import NotesTodo from "features/forms/NotesAndTodo/NotesTodo";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Tags from "@ui/Tags";
import AttributesDisplay from "@ui/AttributesDisplay";
import Swal from "sweetalert2";
import SubJobs from "@ui/SubJobs";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // property: yup.string().required("Select a property to fill this field."),
    // clientName: yup.string().required("Select a property to fill this field."),

    engineerRefId: yup.string().required("This field is required"),
    contractId: yup.string().required("This field is required"),
    jobTypeRefId: yup.string().required("This field is required"),
    jobStateRefId: yup.string().required("This field is required"),
    // cancelReasonRefId: yup.string().required("This field is required"),
    // reference: yup.string().required("This field is required"),
    priorityId: yup.string().required("This field is required"),
    targetDate: yup.string().required("This field is required"),
    scheduledDate: yup.string().required("This field is required"),
    // scheduledStartTime: yup.string().required("This field is required"),
    // scheduledEndTime: yup.string().required("This field is required"),

    // qcDate: yup.string().required("This field is required"),
    // qcStatusRefId: yup.string().required("This field is required"),
    invoiced: yup.bool().required("This field is required"),
    invoiceNo: yup.string().when("invoiced", {
      is: true,
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

export default function ViewJob({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [dropdownData, setDropdownData] = useState({
    jobTypeData: [],
    jobStateData: [],
    contractsData: [],
    engineerData: [],
    priorityData: [],
    qcStatusData: [],
    cancelReasonData: [],
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // Hidden values
      clientRefId: "",
      propertyId: "",
      // Displayed values
      property: "", // Not in POST data to send
      clientName: "", // Not in POST data to send
      jobTypeRefId: "",
      jobStateRefId: "",
      cancelReasonRefId: "",
      contractId: "",
      engineerRefId: "",
      priorityId: "",
      reference: "",
      scheduledDate: "",
      targetDate: "",
      scheduledStartTime: "",
      scheduledEndTime: "",

      completedDate: "",
      completed_flag: "",
      qcDate: "",
      // qcStatusRefId: 0,
      invoiced: "",
      invoiceNo: "",

      // Additional Info Section
      recordCreated: "",
      createStaff: "",
      parentJobId: "",
      cancelReason: "",
      qcStaff: "",
      cancelStaff: "",
      recordModified: "",
      lastUpdateStaff: "",
    },
  });
  const propertyId = watch("propertyId");
  const clientRefId = watch("clientRefId");
  const contractId = watch("contractId");
  const jobTypeRefId = watch("jobTypeRefId");
  const jobStateRefId = watch("jobStateRefId");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  const canHasSubJob = stateData.jobType.canHasSubJob;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form Values", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  //* Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        // Hidden value
        id: stateData.id,
        propertyId: stateData.propertyId || "",
        clientRefId: stateData.clientRefId || "",
        // Displayed values
        property: stateData.property.addrInfo || "",
        clientName: stateData.client || "",
        contractId: stateData.contractId || "",
        engineerRefId: stateData.engineerRefId || "",
        jobTypeRefId: stateData.jobTypeRefId || "",
        jobStateRefId:
          stateData.jobStateRefId !== undefined ? stateData.jobStateRefId : "", // Check specifically for undefined
        cancelReasonRefId: stateData.cancelReasonRefId || "",
        reference: stateData.reference || "",
        priorityId: stateData.priorityId || "",
        targetDate: stateData.targetDate || "",
        scheduledDate: stateData.scheduledDate || "",
        scheduledStartTime:
          Hooks.formatTime(stateData.scheduledStartTime) || "",
        scheduledEndTime: Hooks.formatTime(stateData.scheduledEndTime) || "",

        qcDate: stateData.qcDate || "",
        qcStatusRefId: stateData.qcStatusRefId || 0,
        completedDate: stateData.completedDate || "",
        completed_flag: stateData.completed_flag,
        invoiced: stateData.invoiced,
        invoiceNo: stateData.invoiceNo || "",

        //* Additional Info Section
        recordCreated: new Date(stateData.recordCreated).toDateString() || "",
        createStaff: stateData.createStaff
          ? `${stateData?.createStaff?.forename} ${stateData?.createStaff?.surname}`
          : "",
        parentJobId: stateData.parentJobId || "",
        cancelReason: stateData.cancelReason || "",
        qcStaff: stateData.qcStaff
          ? `${stateData?.qcStaff?.forename} ${stateData?.qcStaff?.surname}`
          : "",
        cancelStaff: stateData.cancelStaff
          ? `${stateData?.cancelStaff?.forename} ${stateData?.cancelStaff?.surname}`
          : "",
        recordModified: new Date(stateData.recordModified).toDateString() || "",
        lastUpdateStaff: stateData.lastUpdateStaff
          ? `${stateData?.lastUpdateStaff?.forename} ${stateData?.lastUpdateStaff?.surname}`
          : "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      //* Client
      Hooks.getData(`${API_ENV}/clients/${stateData.clientRefId}`, (data) => {
        // console.log("clients", data);
        setValue("clientName", data.name);
      });

      //* Contract - OLD
      // if (clientRefId) {
      //   Hooks.getData(
      //     `${API_ENV}/contracts?ClientId=${clientRefId}`,
      //     (data) => {
      //       // console.log("contracts", data);
      //       setDropdownData((prevData) => ({
      //         ...prevData,
      //         contractsData: data,
      //       }));
      //     }
      //   );
      // }

      //* Contract - NEW
      if (propertyId) {
        Hooks.getData(
          `${API_ENV}/contract/properties/${propertyId}?show_expired_contract=true`,
          (data) => {
            // console.log("contracts", data);
            setDropdownData((prevData) => ({
              ...prevData,
              contractsData: data,
            }));
          }
        );
      }

      //* Engineer
      if (contractId) {
        Hooks.getData(`${API_ENV}/engineer/contract/${contractId}`, (data) => {
          // console.log("staff", data);
          setDropdownData((prevData) => ({
            ...prevData,
            engineerData: data,
          }));
        });
      }

      //* Job Type
      if (contractId) {
        Hooks.getData(`${API_ENV}/jobType?ContractId=${contractId}`, (data) => {
          // console.log("jobType", data);
          setDropdownData((prevData) => ({
            ...prevData,
            jobTypeData: data,
          }));
        });
      }

      //* Job State
      if (jobTypeRefId && jobStateRefId !== undefined) {
        Hooks.getData(
          `${API_ENV}/jobState/jobType/${jobTypeRefId}/${jobStateRefId}`,
          (data) => {
            // console.log("jobState", data);
            setDropdownData((prevData) => ({
              ...prevData,
              jobStateData: data,
            }));
          }
        );
      }

      //* Priority
      Hooks.getData(`${API_ENV}/priority`, (data) => {
        // console.log("priority", data);
        if (data.length > 0) {
          setDropdownData((prevData) => ({
            ...prevData,
            priorityData: data,
          }));
        }
      });

      //* QC Status
      Hooks.getData(`${API_ENV}/qcStatus`, (data) => {
        // console.log("qcStatus", data);
        if (data.length > 0) {
          setDropdownData((prevData) => ({
            ...prevData,
            qcStatusData: data,
          }));
        }
      });

      //* Cancel Reason
      if (jobStateRefId === 8) {
        Hooks.getData(
          `${API_ENV}/contractAndCancelReason?contractIds=${contractId}&showarchive=false`,
          (data) => {
            // console.log("cancelReason", data);

            const cancelReasonArray = data.map((item) => ({
              id: item.cancelReasonId,
              name: item.cancelReason.reason,
            }));

            setDropdownData((prevData) => ({
              ...prevData,
              cancelReasonData: cancelReasonArray,
            }));
          }
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    clientRefId,
    contractId,
    jobTypeRefId,
    jobStateRefId,
    setValue,
    stateData.clientRefId,
    stateData.id,
    propertyId,
  ]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("data", data);

      // Destructure and exclude properties
      const {
        property,
        clientName,
        clientRefId,
        recordCreated,
        recordModified,

        createStaff,
        parentJobId,
        cancelReason,
        qcStaff,
        cancelStaff,
        lastUpdateStaff,
        cancelReasonRefId,
        ...restData
      } = data;

      // Handle completedDate
      const completedDate =
        data.completedDate === "" ? null : Hooks.dateToISO(data.completedDate);
      const qcDate = data.qcDate ? Hooks.dateToISO(data.qcDate) : null;

      const transformedData = {
        ...restData,
        completedDate,
        qcDate,
        scheduledStartTime: Hooks.combineDateTime(
          data.scheduledDate,
          data.scheduledStartTime
        ),
        scheduledEndTime: Hooks.combineDateTime(
          data.scheduledDate,
          data.scheduledEndTime
        ),
      };

      //* Handling null values
      if (cancelReasonRefId) {
        transformedData.cancelReasonRefId = cancelReasonRefId;
      }

      transformedData.invoiceNo =
        transformedData.invoiceNo === "" ? null : transformedData.invoiceNo;

      // console.log("data to send", data);
      console.log("transformedData to send", transformedData);

      Hooks.sendData(
        transformedData,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  const handleSubJob = useCallback(() => {
    setFormState({ type: "addsubjob", data: {} });
  }, [setFormState]);

  const handleCloneJob = useCallback(() => {
    const jobId = Hooks.getSidePanelData().id;
    // console.log("jobId", jobId);

    fetch(`${API_ENV}/jobs/clonejob/${jobId}`, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: Hooks.getCookie("jwt"),
      },
    })
      .then((response) => {
        if (response.ok) {
          // Parse the response JSON
          return response.json();
        } else {
          return Promise.reject("Job id could not be created !");
        }
      })
      .then((data) => {
        // Access the 'id' property from the parsed JSON
        const jobId = data.id;

        Swal.fire({
          title: "",
          text: `New Job id ${jobId} created !`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            document.querySelector(".currentTab").click();
            refreshTable();
            cleanURL();
          }
        });
      })
      .catch((error) => {
        // Handle errors
        Hooks.displayError(error);
      });
  }, [cleanURL, refreshTable]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    if (canHasSubJob) {
      setFooterState([
        { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
        { text: "Add Sub Job", click: handleSubJob },
        { text: "Clone Job", click: handleCloneJob },
        { text: "Archive", click: handleArchive },
        // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
        { text: "Close", click: closeFunction },
      ]);
    } else {
      setFooterState([
        { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
        { text: "Clone Job", click: handleCloneJob },
        { text: "Archive", click: handleArchive },
        // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
        { text: "Close", click: closeFunction },
      ]);
    }
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubJob,
    handleCloneJob,
    handleSubmitForm,
    handleArchive,
    canHasSubJob,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow type="full">
            <Input
              label={"Property"}
              name="property"
              control={control}
              errors={errors}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Client"}
              name="clientName"
              control={control}
              errors={errors}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Contract"}
              name="contractId"
              control={control}
              errors={errors}
              disabled={!clientRefId}
              dropdownData={dropdownData.contractsData}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerRefId"
              control={control}
              errors={errors}
              disabled={!contractId}
              dropdownData={dropdownData.engineerData}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job Type"}
              name="jobTypeRefId"
              control={control}
              errors={errors}
              disabled={!contractId}
              dropdownData={dropdownData.jobTypeData}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job State"}
              name="jobStateRefId"
              control={control}
              errors={errors}
              disabled={!contractId}
              dropdownData={dropdownData.jobStateData}
              optionName="description"
            />
          </SidePanelFormRow>

          {jobStateRefId === 8 && (
            <SidePanelFormRow>
              <DropdownList
                label={"Cancel Reason"}
                name="cancelReasonRefId"
                control={control}
                errors={errors}
                disabled={!jobStateRefId}
                dropdownData={dropdownData.cancelReasonData}
              />
            </SidePanelFormRow>
          )}

          <SidePanelFormRow>
            <Input
              label={"WO"}
              name="reference"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Priority"}
              name="priorityId"
              firstOptionValue={0}
              control={control}
              errors={errors}
              dropdownData={dropdownData.priorityData}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Target Date</label>
            <Controller
              name="targetDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={true}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Scheduled Date</label>
            <Controller
              name="scheduledDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={true}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Start Time"}
              name="scheduledStartTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"End Time"}
              name="scheduledEndTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"QC Status"}
              name="qcStatusRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.qcStatusData}
              optionName="description"
              firstOptionValue={0}
              firstOption="No Selection"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>QC Date</label>
            <Controller
              name="qcDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Completed Date</label>
            <Controller
              name="completedDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center readOnly"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                  readOnly
                  // disabled
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Complete?"}
              name="completed_flag"
              type="checkbox"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Invoiced?"}
              name="invoiced"
              control={control}
              errors={errors}
              dropdownData={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Invoice No"}
              name="invoiceNo"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      {/* Notes & Todo go here */}
      <CollapsibleGroup title="Notes & Todo">
        <NotesTodo jobId={stateData.id} />
      </CollapsibleGroup>

      {/* //. Tags  */}
      <CollapsibleGroup title="Tags">
        <Tags
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={1}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>

      {/* //. Attributes  */}
      <CollapsibleGroup title="Attributes">
        <AttributesDisplay
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={1}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>

      {stateData.hasSubjobs && (
        <CollapsibleGroup title="Sub Jobs">
          <SubJobs parentJobId={stateData.id} />
        </CollapsibleGroup>
      )}

      <CollapsibleGroup title="Additional Information">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Creation Date"}
              name="recordCreated"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Created By"}
              name="createStaff"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Parent Job Number"}
              name="parentJobId"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          {/* 
          <SidePanelFormRow>
            <Input
              label={"Cancel Reason"}
              name="cancelReason"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow> */}

          <SidePanelFormRow>
            <Input
              label={"QC Staff"}
              name="qcStaff"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Cancel Staff"}
              name="cancelStaff"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Last Modified Date</label>
            <Controller
              name="recordModified"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center readOnly"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                  readOnly
                  // disabled
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Last Modified By"}
              name="lastUpdateStaff"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
