import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatISO } from "date-fns";

const DateTimePicker = forwardRef(function DateTimePicker(
  {
    id,
    classValue,
    value,
    hideTime,
    onChange,
    error,
    onlyShowTime,
    readOnly = false,
    disabled = false,
  },
  ref
) {
  const [startDate, setStartDate] = useState(null);

  // Define the format based on whether time is hidden
  const format = hideTime ? "MMMM dd yyyy" : "MMMM dd yyyy, h:mm a";

  // Correcting the special case date to a default value
  if (value === "0001-01-01T00:00:00") {
    value = "2000-01-01T00:00:01";
  }

  useEffect(() => {
    if (value) {
      // Parse the incoming value as UTC and convert to the browser's timezone
      const date = new Date(value);
      setStartDate(date);
    } else {
      setStartDate(null);
    }
  }, [value]);

  function handleChange(date) {
    setStartDate(date);
    if (date) {
      // Convert the selected date to UTC without timezone offset
      const utcDate = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes()
        )
      );
      const isoString = formatISO(utcDate);
      onChange(isoString); // Notify the parent component of the change
    } else {
      onChange(null); // Handle case when date is null
    }
  }

  return (
    <>
      <DatePicker
        ref={ref}
        id={id}
        className={classValue}
        selected={startDate}
        showTimeSelect={!hideTime}
        showTimeSelectOnly={onlyShowTime}
        timeIntervals={15}
        dateFormat={format}
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      {error && <p className="fieldError">{error.message}</p>}
    </>
  );
});

export default DateTimePicker;
