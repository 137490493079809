import React from "react";

export default function SingleNote({ content }) {
  //   useEffect(() => {
  //     console.log("content", content);
  //   }, [content]);

  if (typeof content === "string") {
    return (
      <div className="singleNote">
        <div className="singleNoteHeader">{content}</div>
      </div>
    );
  } else {
    let actionEvent = "Nil";
    try {
      actionEvent = content.actionEvent.name;
    } catch (e) {}

    return (
      <div className="singleNote">
        <div className="singleNoteHeader">
          <div>{`${content.staff.forename} ${content.staff.surname}`}</div>

          <div>{`${new Date(content.dateAdded).toDateString()}`}</div>

          <div>{actionEvent}</div>
        </div>

        <div className="singleNoteContent">
          {content.content || content.notes}
        </div>
      </div>
    );
  }
}
