export const navData = [
  // Links
  {
    label: "Dashboard",
    level: 0,
    type: "link",
    urlPath: "/dashboard",
    icon: "HiOutlineChartPie",
  },
  {
    label: "Calendar",
    level: 0,
    type: "link",
    urlPath: "/calendar",
    icon: "HiOutlineCalendarDays",
  },

  // Groups
  // * Operations --------------------------------------------------------------------
  {
    label: "Operations",
    level: 0,
    type: "group",
    icon: "HiOutlineCircleStack",
    contents: [
      {
        label: "Clients",
        level: 1,
        type: "link",
        urlPath: "/operations/clients",
      },
      {
        label: "Contracts",
        level: 1,
        type: "link",
        urlPath: "/operations/contracts",
      },
      {
        label: "Properties",
        level: 1,
        type: "link",
        urlPath: "/operations/properties",
      },
      {
        label: "Jobs",
        level: 1,
        type: "link",
        urlPath: "/operations/jobs",
      },
      {
        label: "Inspection Sets",
        level: 1,
        type: "link",
        urlPath: "/operations/inspection-sets",
      },
    ],
  },

  // * Warehouse --------------------------------------------------------------------
  {
    label: "Warehouse",
    level: 0,
    type: "group",
    icon: "HiOutlineTruck",
    contents: [
      {
        label: "Warehouses",
        level: 1,
        type: "link",
        urlPath: "/warehouse/warehouses",
      },
      {
        label: "Parts",
        level: 1,
        type: "link",
        urlPath: "/warehouse/parts",
      },
      {
        label: "Kits",
        level: 1,
        type: "link",
        urlPath: "/warehouse/kits",
      },
      {
        label: "Stock",
        level: 1,
        type: "link",
        urlPath: "/warehouse/stock",
      },
      {
        label: "Stock Movements",
        level: 1,
        type: "link",
        urlPath: "/warehouse/stock-movements",
      },
      {
        label: "Goods Receiving",
        level: 1,
        type: "link",
        urlPath: "/warehouse/deliveries",
      },
      {
        label: "Stock Cycle Count",
        level: 1,
        type: "link",
        urlPath: "/warehouse/stock-cycle-count",
      },
      {
        label: "Suppliers",
        level: 1,
        type: "link",
        urlPath: "/warehouse/suppliers",
      },
    ],
  },

  // * Purchasing --------------------------------------------------------------------
  {
    label: "Purchasing",
    level: 0,
    type: "group",
    icon: "HiOutlineWallet",
    contents: [
      {
        label: "Purchase Requests",
        level: 1,
        type: "link",
        urlPath: "/purchasing/purchase-requests",
      },
      {
        label: "Purchase Orders",
        level: 1,
        type: "link",
        urlPath: "/purchasing/purchase-orders",
      },
    ],
  },

  // * Finance --------------------------------------------------------------------
  {
    label: "Finance",
    level: 0,
    type: "group",
    icon: "HiOutlineCurrencyPound",
    contents: [
      // {
      //   label: "",
      //   level: 1,
      //   type: "link",
      //   urlPath: "/finance/",
      // },
    ],
  },

  // * Estimating --------------------------------------------------------------------
  {
    label: "Estimating",
    level: 0,
    type: "group",
    icon: "HiOutlineArrowTrendingUp",
    contents: [
      // {
      //   label: "",
      //   level: 1,
      //   type: "link",
      //   urlPath: "/estimating/",
      // },
    ],
  },

  // * Compliance --------------------------------------------------------------------
  {
    label: "Compliance",
    level: 0,
    type: "group",
    icon: "HiOutlineCheckCircle",
    contents: [
      // {
      //   label: "",
      //   level: 1,
      //   type: "link",
      //   urlPath: "/compliance/",
      // },
    ],
  },

  // * System --------------------------------------------------------------------
  {
    label: "System",
    level: 0,
    type: "group",
    icon: "HiOutlineTv",
    contents: [
      {
        label: "Define Reports",
        level: 1,
        type: "link",
        urlPath: "/system/define-reports",
      },
      {
        label: "Report Online (CSV/XLS)",
        level: 1,
        type: "link",
        urlPath: "/system/report-online-csv-xls",
      },
      {
        label: "Report Online (PDF)",
        level: 1,
        type: "link",
        urlPath: "/system/report-online-pdf",
      },
      {
        label: "Report as File or Email",
        level: 1,
        type: "link",
        urlPath: "/system/report-file-email",
      },
      {
        label: "View All Report Process",
        level: 1,
        type: "link",
        urlPath: "/system/report-process",
      },
      {
        label: "Letter Print Setup",
        level: 1,
        type: "link",
        urlPath: "/system/letter-print-setup",
      },
      {
        label: "View Letter Print Process",
        level: 1,
        type: "link",
        urlPath: "/system/letter-print-process",
      },
      {
        label: "View All Program Process",
        level: 1,
        type: "link",
        urlPath: "/system/all-program-process",
      },
      {
        label: "View Scheduler Process",
        level: 1,
        type: "link",
        urlPath: "/system/scheduler-process",
      },
      {
        label: "Ticket",
        level: 1,
        type: "link",
        urlPath: "/system/ticket",
      },
      {
        label: "Ticket Type",
        level: 1,
        type: "link",
        urlPath: "/system/ticket-type",
      },
    ],
  },

  // * My Account --------------------------------------------------------------------
  // {
  //   label: "My Account",
  //   level: 0,
  //   type: "group",
  //   icon: "HiOutlineUser",
  //   contents: [
  //     {
  //       label: "Logout",
  //       level: 1,
  //       type: "link",
  //       urlPath: "/logout",
  //     },
  //   ],
  // },

  // * Settings --------------------------------------------------------------------
  {
    label: "Settings",
    level: 0,
    type: "group",
    icon: "HiOutlineCog6Tooth",
    contents: [
      // . Operations SubGroup --------------------------------------------------------------------
      {
        label: "Operations",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Action Events",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/action-event",
          },
          {
            label: "Document Tags",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/document-tags",
          },
          {
            label: "Cancel Reason",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/cancel-reason",
          },
          {
            label: "Priority",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/priority",
          },
          {
            label: "QC Status",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/qc-status",
          },
          {
            label: "SOR",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/sor",
          },
          {
            label: "Answer Type",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/answer-type",
          },
          {
            label: "Object Document Type",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/object-document-type",
          },
          {
            label: "Attribute Category",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/attribute-category",
          },
          {
            label: "Attribute Unit",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/attribute-unit",
          },
          {
            label: "Tags",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/tags",
          },
          {
            label: "Document Workflow",
            level: 2,
            type: "link",
            urlPath: "/settings/operations/document-workflow",
          },
        ],
      },

      // . Equipment SubGroup --------------------------------------------------------------------
      {
        label: "Equipment",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Equipment Category",
            level: 2,
            type: "link",
            urlPath: "/settings/equipment/equipment-category",
          },
          {
            label: "Equipment Subcategory 1",
            level: 2,
            type: "link",
            urlPath: "/settings/equipment/equipment-subcategory1",
          },
          {
            label: "Equipment Subcategory 2",
            level: 2,
            type: "link",
            urlPath: "/settings/equipment/equipment-subcategory2",
          },
          {
            label: "Equipment Type",
            level: 2,
            type: "link",
            urlPath: "/settings/equipment/equipment-type",
          },
        ],
      },

      // . Jobs SubGroup --------------------------------------------------------------------
      {
        label: "Jobs",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Jobs Category",
            level: 2,
            type: "link",
            urlPath: "/settings/jobs/job-category",
          },
          {
            label: "Job Type",
            level: 2,
            type: "link",
            urlPath: "/settings/jobs/job-type",
          },
          {
            label: "Job State",
            level: 2,
            type: "link",
            urlPath: "/settings/jobs/job-state",
          },
          {
            label: "Job State Transitions",
            level: 2,
            type: "link",
            urlPath: "/settings/jobs/job-state-transitions",
          },
        ],
      },

      // . Visits SubGroup --------------------------------------------------------------------
      {
        label: "Visits",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Visit State",
            level: 2,
            type: "link",
            urlPath: "/settings/visits/visit-state",
          },
          {
            label: "Visit State Transitions",
            level: 2,
            type: "link",
            urlPath: "/settings/visits/visit-state-transitions",
          },
        ],
      },

      // . Parts SubGroup --------------------------------------------------------------------
      {
        label: "Parts",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Parts Category",
            level: 2,
            type: "link",
            urlPath: "/settings/parts/parts-category",
          },
          {
            label: "Parts Subcategory 1",
            level: 2,
            type: "link",
            urlPath: "/settings/parts/parts-subcategory1",
          },
          {
            label: "Parts Subcategory 2",
            level: 2,
            type: "link",
            urlPath: "/settings/parts/parts-subcategory2",
          },
          {
            label: "Parts Subcategory 3",
            level: 2,
            type: "link",
            urlPath: "/settings/parts/parts-subcategory3",
          },
          {
            label: "Parts Subcategory 4",
            level: 2,
            type: "link",
            urlPath: "/settings/parts/parts-subcategory4",
          },
        ],
      },

      // . Purchasing SubGroup --------------------------------------------------------------------
      {
        label: "Purchasing",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Purchase Request State",
            level: 2,
            type: "link",
            urlPath: "/settings/purchasing/purchase-request-state",
          },
          {
            label: "Purchase Order State",
            level: 2,
            type: "link",
            urlPath: "/settings/purchasing/purchase-order-state",
          },
          {
            label: "Purchase Order Delivery State",
            level: 2,
            type: "link",
            urlPath: "/settings/purchasing/po-detail-state",
          },
          {
            label: "Delivery Notes State",
            level: 2,
            type: "link",
            urlPath: "/settings/purchasing/delivery-note-state",
          },
          {
            label: "Delivery Notes Detail State",
            level: 2,
            type: "link",
            urlPath: "/settings/purchasing/dn-detail-state",
          },
        ],
      },

      // . Finance SubGroup --------------------------------------------------------------------
      {
        label: "Finance",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Cost Types",
            level: 2,
            type: "link",
            urlPath: "/settings/finance/cost-type",
          },
          {
            label: "Cost Codes",
            level: 2,
            type: "link",
            urlPath: "/settings/finance/cost-code",
          },
        ],
      },

      // . Staff SubGroup --------------------------------------------------------------------
      {
        label: "Staff",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Staff",
            level: 2,
            type: "link",
            urlPath: "/settings/staff/staff",
          },
          {
            label: "Staff Type",
            level: 2,
            type: "link",
            urlPath: "/settings/staff/staff-type",
          },
          {
            label: "Certifications",
            level: 2,
            type: "link",
            urlPath: "/settings/staff/certifications",
          },
        ],
      },

      // . Roles SubGroup --------------------------------------------------------------------
      {
        label: "Roles",
        level: 1,
        type: "group",
        contents: [
          {
            label: "Role",
            level: 2,
            type: "link",
            urlPath: "/settings/roles/role",
          },
          {
            label: "API Group",
            level: 2,
            type: "link",
            urlPath: "/settings/roles/api-group",
          },
          {
            label: "API",
            level: 2,
            type: "link",
            urlPath: "/settings/roles/api",
          },
        ],
      },
    ],
  },
];
