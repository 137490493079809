import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "../src/styles/GlobalStyles.scss";
import "../src/styles/features/Forms.scss";

import { AuthProvider } from "./context/AuthContext";
import { TableDataProvider } from "./context/TableDataContext";
import { DarkModeProvider } from "./context/DarkModeContext";
import { ModalProvider } from "./ui/Modal";

import Logout from "./pages/Logout";
import Error from "./pages/Error";

import AppLayout from "./ui/AppLayout";
import ProtectedRoute from "./ui/ProtectedRoute";

import Login from "./pages/Login";
import Dashboard from "./features/dashboard/Dashboard";
import Calendar from "./features/calendar/Calendar";
import InfoDisplay from "./features/infoDisplay/InfoDisplay";
import { TempFixProvider } from "./context/AddJobQuickFix";

import Test from "pages/Test";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <TempFixProvider>
      <DarkModeProvider>
        <AuthProvider>
          <BrowserRouter>
            <TableDataProvider>
              <ModalProvider>
                <Routes>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<AppLayout />}>
                      <Route
                        index
                        element={<Navigate replace to="dashboard" />}
                      />

                      <Route path="test" element={<Test />} />

                      <Route path="dashboard" element={<Dashboard />} />

                      <Route path="calendar" element={<Calendar />} />

                      {/* //* Operations -------------------------------------------------------------------- */}
                      <Route
                        path="operations/clients/:tableRowId?"
                        element={<InfoDisplay setting={"clients"} />}
                      />
                      <Route
                        path="operations/contracts/:tableRowId?"
                        element={<InfoDisplay setting={"contracts"} />}
                      />
                      <Route
                        path="operations/properties/:tableRowId?"
                        element={<InfoDisplay setting={"properties"} />}
                      />
                      <Route
                        path="operations/jobs/:tableRowId?"
                        element={<InfoDisplay setting={"jobs"} />}
                      />
                      <Route
                        path="operations/inspection-sets/:tableRowId?"
                        element={<InfoDisplay setting={"inspectionsets"} />}
                      />

                      {/* //* Warehouse -------------------------------------------------------------------- */}
                      <Route
                        path="warehouse/warehouses/:tableRowId?"
                        element={<InfoDisplay setting={"warehouses"} />}
                      />
                      <Route
                        path="warehouse/parts/:tableRowId?"
                        element={<InfoDisplay setting={"parts"} />}
                      />
                      <Route
                        path="warehouse/kits/:tableRowId?"
                        element={<InfoDisplay setting={"kits"} />}
                      />
                      <Route
                        path="warehouse/stock/:tableRowId?"
                        element={<InfoDisplay setting={"stock"} />}
                      />
                      <Route
                        path="warehouse/stock-movements/:tableRowId?"
                        element={<InfoDisplay setting={"stockmovements"} />}
                      />
                      <Route
                        path="warehouse/deliveries/:tableRowId?"
                        element={<InfoDisplay setting={"deliveries"} />}
                      />
                      <Route
                        path="warehouse/stock-cycle-count/:tableRowId?"
                        element={<InfoDisplay setting={"stockcyclecount"} />}
                      />
                      <Route
                        path="warehouse/suppliers/:tableRowId?"
                        element={<InfoDisplay setting={"suppliers"} />}
                      />

                      {/* //* Purchasing -------------------------------------------------------------------- */}
                      <Route
                        path="purchasing/suppliers/:tableRowId?"
                        element={<InfoDisplay setting={"suppliers"} />}
                      />
                      <Route
                        path="purchasing/purchase-requests/:tableRowId?"
                        element={<InfoDisplay setting={"purchaserequests"} />}
                      />
                      <Route
                        path="purchasing/purchase-orders/:tableRowId?"
                        element={<InfoDisplay setting={"purchaseorders"} />}
                      />

                      {/* //* Finance -------------------------------------------------------------------- */}

                      {/* //* Estimating -------------------------------------------------------------------- */}

                      {/* //* Compliance -------------------------------------------------------------------- */}

                      {/* //* My Account -------------------------------------------------------------------- */}

                      {/* //* System -------------------------------------------------------------------- */}
                      <Route
                        path="system/define-reports/:tableRowId?"
                        element={<InfoDisplay setting={"runreportlist"} />}
                      />
                      <Route
                        path="system/report-online-csv-xls/:tableRowId?"
                        element={
                          <InfoDisplay setting={"runreportasonlinecsvxls"} />
                        }
                      />
                      <Route
                        path="system/report-online-pdf/:tableRowId?"
                        element={
                          <InfoDisplay setting={"runreportasonlinepdf"} />
                        }
                      />
                      <Route
                        path="system/report-file-email/:tableRowId?"
                        element={
                          <InfoDisplay setting={"runreportasfileoremail"} />
                        }
                      />
                      <Route
                        path="system/report-process/:tableRowId?"
                        element={<InfoDisplay setting={"runreportproc"} />}
                      />
                      <Route
                        path="system/letter-print-setup/:tableRowId?"
                        element={<InfoDisplay setting={"letterprintsetup"} />}
                      />
                      <Route
                        path="system/letter-print-process/:tableRowId?"
                        element={<InfoDisplay setting={"runcustomprocess"} />}
                      />
                      <Route
                        path="system/all-program-process/:tableRowId?"
                        element={<InfoDisplay setting={"runprogramproc"} />}
                      />
                      <Route
                        path="system/scheduler-process/:tableRowId?"
                        element={
                          <InfoDisplay setting={"runschedulermessage"} />
                        }
                      />
                      <Route
                        path="system/ticket/:tableRowId?"
                        element={<InfoDisplay setting={"ticket"} />}
                      />
                      <Route
                        path="system/ticket-type/:tableRowId?"
                        element={<InfoDisplay setting={"tickettype"} />}
                      />

                      {/* //* Settings -------------------------------------------------------------------- */}
                      {/* //. Operations -------------------------------------------------------------------- */}
                      <Route
                        path="settings/operations/action-event/:tableRowId?"
                        element={<InfoDisplay setting={"actionevent"} />}
                      />
                      <Route
                        path="settings/operations/document-tags/:tableRowId?"
                        element={<InfoDisplay setting={"documenttags"} />}
                      />
                      <Route
                        path="settings/operations/cancel-reason/:tableRowId?"
                        element={<InfoDisplay setting={"cancelreason"} />}
                      />
                      <Route
                        path="settings/operations/priority/:tableRowId?"
                        element={<InfoDisplay setting={"priority"} />}
                      />
                      <Route
                        path="settings/operations/qc-status/:tableRowId?"
                        element={<InfoDisplay setting={"qcstatus"} />}
                      />
                      <Route
                        path="settings/operations/sor/:tableRowId?"
                        element={<InfoDisplay setting={"sor"} />}
                      />
                      <Route
                        path="settings/operations/answer-type/:tableRowId?"
                        element={<InfoDisplay setting={"answertype"} />}
                      />
                      <Route
                        path="settings/operations/object-document-type/:tableRowId?"
                        element={<InfoDisplay setting={"objectdoctype"} />}
                      />
                      <Route
                        path="settings/operations/attribute-category/:tableRowId?"
                        element={<InfoDisplay setting={"attributecategory"} />}
                      />
                      <Route
                        path="settings/operations/attribute-unit/:tableRowId?"
                        element={<InfoDisplay setting={"attributeunit"} />}
                      />
                      <Route
                        path="settings/operations/tags/:tableRowId?"
                        element={<InfoDisplay setting={"tags"} />}
                      />
                      <Route
                        path="settings/operations/document-workflow/:tableRowId?"
                        element={<InfoDisplay setting={"documentworkflow"} />}
                      />

                      {/* //. Equipment -------------------------------------------------------------------- */}
                      <Route
                        path="settings/equipment/equipment-category/:tableRowId?"
                        element={<InfoDisplay setting={"equipmentcategory"} />}
                      />
                      <Route
                        path="settings/equipment/equipment-subcategory1/:tableRowId?"
                        element={
                          <InfoDisplay setting={"equipmentsubcategory1"} />
                        }
                      />
                      <Route
                        path="settings/equipment/equipment-subcategory2/:tableRowId?"
                        element={
                          <InfoDisplay setting={"equipmentsubcategory2"} />
                        }
                      />
                      <Route
                        path="settings/equipment/equipment-type/:tableRowId?"
                        element={<InfoDisplay setting={"equipmenttype"} />}
                      />

                      {/* //. Jobs -------------------------------------------------------------------- */}
                      <Route
                        path="settings/jobs/job-category/:tableRowId?"
                        element={<InfoDisplay setting={"jobcategory"} />}
                      />
                      <Route
                        path="settings/jobs/job-type/:tableRowId?"
                        element={<InfoDisplay setting={"jobtype"} />}
                      />
                      <Route
                        path="settings/jobs/job-state/:tableRowId?"
                        element={<InfoDisplay setting={"jobstate"} />}
                      />
                      <Route
                        path="settings/jobs/job-state-transitions/:tableRowId?"
                        element={
                          <InfoDisplay setting={"jobstatetransitions"} />
                        }
                      />

                      {/* //. Visits -------------------------------------------------------------------- */}
                      <Route
                        path="settings/visits/visit-state/:tableRowId?"
                        element={<InfoDisplay setting={"visitstate"} />}
                      />
                      <Route
                        path="settings/visits/visit-state-transitions/:tableRowId?"
                        element={
                          <InfoDisplay setting={"visitstatetransitions"} />
                        }
                      />

                      {/* //. Parts -------------------------------------------------------------------- */}
                      <Route
                        path="settings/parts/parts-category/:tableRowId?"
                        element={<InfoDisplay setting={"partscategory"} />}
                      />
                      <Route
                        path="settings/parts/parts-subcategory1/:tableRowId?"
                        element={<InfoDisplay setting={"partssubcategory1"} />}
                      />
                      <Route
                        path="settings/parts/parts-subcategory2/:tableRowId?"
                        element={<InfoDisplay setting={"partssubcategory2"} />}
                      />
                      <Route
                        path="settings/parts/parts-subcategory3/:tableRowId?"
                        element={<InfoDisplay setting={"partssubcategory3"} />}
                      />
                      <Route
                        path="settings/parts/parts-subcategory4/:tableRowId?"
                        element={<InfoDisplay setting={"partssubcategory4"} />}
                      />

                      {/* //. Purchasing -------------------------------------------------------------------- */}
                      <Route
                        path="settings/purchasing/purchase-request-state/:tableRowId?"
                        element={
                          <InfoDisplay setting={"purchaserequeststate"} />
                        }
                      />
                      <Route
                        path="settings/purchasing/purchase-order-state/:tableRowId?"
                        element={<InfoDisplay setting={"purchaseorderstate"} />}
                      />
                      <Route
                        path="settings/purchasing/po-detail-state/:tableRowId?"
                        element={<InfoDisplay setting={"podetailstate"} />}
                      />
                      <Route
                        path="settings/purchasing/delivery-note-state/:tableRowId?"
                        element={<InfoDisplay setting={"deliverynotestate"} />}
                      />
                      <Route
                        path="settings/purchasing/dn-detail-state/:tableRowId?"
                        element={<InfoDisplay setting={"dndetailstate"} />}
                      />

                      {/* //. Finance -------------------------------------------------------------------- */}
                      <Route
                        path="settings/finance/cost-type/:tableRowId?"
                        element={<InfoDisplay setting={"costtype"} />}
                      />
                      <Route
                        path="settings/finance/cost-code/:tableRowId?"
                        element={<InfoDisplay setting={"costcode"} />}
                      />

                      {/* //. Staff -------------------------------------------------------------------- */}
                      <Route
                        path="settings/staff/staff/:tableRowId?"
                        element={<InfoDisplay setting={"staff"} />}
                      />
                      <Route
                        path="settings/staff/staff-type/:tableRowId?"
                        element={<InfoDisplay setting={"stafftype"} />}
                      />
                      <Route
                        path="settings/staff/certifications/:tableRowId?"
                        element={<InfoDisplay setting={"certifications"} />}
                      />

                      {/* //. Roles -------------------------------------------------------------------- */}
                      <Route
                        path="settings/roles/role/:tableRowId?"
                        element={<InfoDisplay setting={"role"} />}
                      />
                      <Route
                        path="settings/roles/api-group/:tableRowId?"
                        element={<InfoDisplay setting={"apigroup"} />}
                      />
                      <Route
                        path="settings/roles/api/:tableRowId?"
                        element={<InfoDisplay setting={"api"} />}
                      />

                      <Route path="/logout" element={<Logout />} />
                      <Route path="*" element={<Error />} />
                    </Route>
                  </Route>
                  <Route path="login" element={<Login />} />
                  <Route path="*" element={<Error />} />
                </Routes>

                <Toaster
                  position="bottom-right"
                  gutter={12}
                  containerStyle={{ margin: "8px" }}
                  toastOptions={{
                    success: {
                      duration: 3000,
                    },
                    error: {
                      duration: 5000,
                    },
                    style: {
                      border: "2px solid white",
                      fontSize: "16px",
                      maxWidth: "500px",
                      padding: "16px 24px",
                      backgroundColor: "#111827",
                      color: "white",
                    },
                  }}
                />
              </ModalProvider>
            </TableDataProvider>
          </BrowserRouter>
        </AuthProvider>
      </DarkModeProvider>
    </TempFixProvider>
  );
}
