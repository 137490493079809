import API_ENV from "@config";
import "./ReportOnlineHeader.scss";

import { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import { useTableData } from "../../../context/TableDataContext";
import { useLocation } from "react-router-dom";

import ReportFilter from "../../filters/ReportFilter";

import Button from "../../../ui/Button";
import Row from "../../../ui/Row";
import { Modal, ModalTrigger } from "../../../ui/Modal";
import toast from "react-hot-toast";

const initialDropdownValues = {
  apiGroupData: [],
  reportNameData: [],
  exportFormatData: [],
};

const initialFilterInfo = {
  // filterSaveId: "",
  // filterSaveName: "",
  selectedFilterId: "",
  selectedFilterName: "",
  savedFilters: [],
  // saveAs: 0,
};

const initialContextFormData = {
  apiGroup: "",
  reportId: "",
  reportTypeId: "",
  outputFormat: "",
  orderBy: "",
  searchFilter: "",
  runReportFilterId: "",
};

export default function ReportOnlineHeader({ setInfoDisplayTableHeadings }) {
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);
  const [filterInfo, setFilterInfo] = useState(initialFilterInfo);
  const location = useLocation();

  const {
    // setTableData,
    generateTableHeadings,
    tableHeadings,
    setTableHeadings,
    tableContextFormData,
    setTableContextFormData,
    fetchTable,
  } = useTableData();

  // useEffect(() => {
  //   // console.log("tableContextformData:", tableContextFormData);
  //   console.log("filterInfo:", filterInfo);
  //   // console.log("tableHeadingsData:", tableHeadingsData);
  //   // console.log("location.pathname:", location.pathname);
  // }, [filterInfo]);

  useEffect(() => {
    setDropdownData(initialDropdownValues);
  }, [location.pathname]);

  //* Clears Form Data on different Report Online page loads
  useEffect(() => {
    if (
      location.pathname === "/system/report-online-csv-xls" ||
      location.pathname === "/system/report-online-pdf"
    ) {
      setTableContextFormData(initialContextFormData);
      setFilterInfo(initialFilterInfo);
    }
  }, [location.pathname, setTableContextFormData]);

  // * filterInfo GET
  useEffect(() => {
    if (tableContextFormData.reportId) {
      // Saved Filters
      Hooks.getData(
        `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${tableContextFormData.reportId}`,
        (data) => {
          // console.log("savedFilters: ", data);

          if (data.length > 0) {
            setFilterInfo((prevData) => ({
              ...prevData,
              savedFilters: data,
              selectedFilterId: data[0].id,
              selectedFilterName: data[0].filterName,
            }));
          } else {
            setFilterInfo(initialFilterInfo);
            toast.error(`No Filter Selected`);
          }
        }
      );
    }
  }, [tableContextFormData.reportId]);

  //* Clear filter ID when there is no saved Filters & Set runReportFilterId for table context
  useEffect(() => {
    if (filterInfo.savedFilters.length > 0) {
      setTableContextFormData((prevData) => ({
        ...prevData,
        runReportFilterId: filterInfo.selectedFilterId,
      }));

      toast.success(`Filter: "${filterInfo.selectedFilterName}" Selected`);
    } else {
      setTableContextFormData((prevData) => ({
        ...prevData,
        runReportFilterId: "",
      }));
    }
  }, [
    filterInfo.savedFilters,
    filterInfo.selectedFilterId,
    setTableContextFormData,
    filterInfo.selectedFilterName,
  ]);

  //* handleLoadFilter if it was automatic on selection
  // useEffect(() => {
  //   if (filterInfo.selectedFilterId) {
  //     // *Apply Filters
  //     Hooks.getData(
  //       `${API_ENV}/runReportfilter/runReportId/${tableContextFormData.reportId}?FilterName=${filterInfo.selectedFilterName}`,
  //       (data) => {
  //         console.log("Set Filters: ", data);

  //         const filterObject = {
  //           staffId: Hooks.getCookie("userId"),
  //           sortColumnFieldId: data.sortColumnFieldId,
  //           runReportListId: tableContextFormData.reportId,
  //           contents: data.contents,
  //         };

  //         console.log("filterObject", filterObject);

  //         Hooks.sendData(
  //           filterObject,
  //           `${API_ENV}/runReportfilter`,
  //           "POST",
  //           null,
  //           false,
  //           `Filter: "${data.filterName}" Selected`
  //         );
  //       }
  //     );
  //   }
  // }, [
  //   filterInfo.selectedFilterId,
  //   filterInfo.selectedFilterName,
  //   tableContextFormData.reportId,
  // ]);

  // function handleLoadFilter() {
  //   if (filterInfo.selectedFilterId) {
  //     // *Apply Filters
  //     Hooks.getData(
  //       `${API_ENV}/runReportfilter/runReportId/${tableContextFormData.reportId}?FilterName=${filterInfo.selectedFilterName}`,
  //       (data) => {
  //         // console.log("Set Filters: ", data);

  //         const filterObject = {
  //           staffId: Hooks.getCookie("userId"),
  //           sortColumnFieldId: data.sortColumnFieldId,
  //           runReportListId: tableContextFormData.reportId,
  //           contents: data.contents,
  //         };

  //         // console.log("filterObject", filterObject);

  //         Hooks.sendData(
  //           filterObject,
  //           `${API_ENV}/runReportfilter`,
  //           "POST",
  //           null,
  //           false,
  //           `Filter: "${data.filterName}" Selected`
  //         );
  //       }
  //     );

  //     setTableContextFormData((prevData) => ({
  //       ...prevData,
  //       runReportFilterId: filterInfo.selectedFilterId,
  //     }));
  //   }
  // }

  // API Group
  useEffect(() => {
    if (
      location.pathname === "/system/report-online-csv-xls" ||
      location.pathname === "/system/report-online-pdf"
    )
      Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          apiGroupData: data,
        }));
      });
  }, [location.pathname]);

  // Report Name
  useEffect(() => {
    if (tableContextFormData.apiGroup) {
      let url;
      if (location.pathname === "/system/report-online-csv-xls") {
        url = `${API_ENV}/runReportlist/accesslist?apigroupid=${tableContextFormData.apiGroup}&isShowAll=false&isShowPDF=false`;
      } else if (location.pathname === "/system/report-online-pdf") {
        url = `${API_ENV}/runReportlist/accesslist?apigroupid=${tableContextFormData.apiGroup}&isShowAll=false&isShowPDF=true`;
      }

      Hooks.getData(url, (data) => {
        // console.log(data);

        setDropdownData((prevData) => ({
          ...prevData,
          reportNameData: data,
        }));
      });
    }
  }, [tableContextFormData.apiGroup, location.pathname]);

  // Populating Order By: & Search Filter fields
  useEffect(() => {
    if (tableContextFormData.reportId) {
      Hooks.getData(
        `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${tableContextFormData.reportId}`,
        (data) => {
          // console.log(data);
          if (data && data.length > 0) {
            const firstItem = data[0];

            setTableContextFormData((prevData) => ({
              ...prevData,
              orderBy: firstItem.displayOrderBy,
              searchFilter: firstItem.displaySearchFilter,
            }));
          } else {
            setTableContextFormData((prevData) => ({
              ...prevData,
              // orderBy: firstItem.displayOrderBy,
              searchFilter: "No Filter",
            }));
          }
        }
      );
    }
  }, [tableContextFormData.reportId, setTableContextFormData]);

  // HANDLE functions
  function handlePreview() {
    // generateTableHeadings needs to be in here as well for sorting to work
    generateTableHeadings();
    // setInfoDisplayTableHeadings(tableHeadings);

    setTableHeadings(tableHeadings);
    fetchTable();
  }

  async function handleDownloadReportCSV() {
    try {
      const apiUrl = `${API_ENV}/runReportList/runonline/download/${tableContextFormData.reportId}`;
      console.log(apiUrl);

      // Fetch the data and wait for the response
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const selectElement = document.getElementById("reportName");
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const reportName = selectedOption.getAttribute("data-reportname");

      const fileName = `${reportName}${tableContextFormData.outputFormat}`;

      if (!reportName) {
        throw new Error(
          "Selected option does not have data-reportname attribute"
        );
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a download link for the file
      const url = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement("a"), {
        href: url,
        download: fileName,
      });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={"reportOnlineHeaderContainer"}>
      <Row type="horizontal" className={"displayHeaderRow"}>
        <label htmlFor="apiGroup">API Group</label>
        <select
          id="apiGroup"
          className="select extraSmall"
          value={tableContextFormData.apiGroup}
          onChange={(e) => {
            setTableContextFormData((prevData) => ({
              ...prevData,
              apiGroup: e.target.value,
              reportId: "", // Clear reportId
              reportTypeId: "", // Clear reportTypeId
            }));
          }}
        >
          <option value="">Select option</option>
          {Array.isArray(dropdownData.apiGroupData)
            ? dropdownData.apiGroupData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.groupName}
                </option>
              ))
            : null}
        </select>

        {/* onChange is too dynamic & needs to be normal JSX */}
        <label htmlFor="reportName">Report Name</label>
        <select
          id="reportName"
          className="select extraSmall"
          value={tableContextFormData.reportId}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const newReportTypeId = selectedOption.dataset.reporttypeid;

            setTableContextFormData((prevData) => ({
              ...prevData,
              reportId: e.target.value,
              reportTypeId: Number(newReportTypeId),
            }));
          }}
        >
          <option value="">Select option</option>
          {Array.isArray(dropdownData.reportNameData)
            ? dropdownData.reportNameData.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  data-reportname={item.name}
                  data-reporttypeid={item.runReportTypeId}
                >
                  {item.name}
                </option>
              ))
            : null}
        </select>
      </Row>

      <Row type="horizontal" className={"displayHeaderRow"}>
        <label>Select Filters:</label>
        <select
          className="select extraSmall"
          value={filterInfo.selectedFilterId}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedFilterName =
              selectedOption.getAttribute("data-filtername");

            setFilterInfo((prevData) => ({
              ...prevData,
              selectedFilterId: Number(e.target.value),
              selectedFilterName: selectedFilterName,
            }));
          }}
        >
          {/* <option value="">Select option</option> */}
          {Array.isArray(filterInfo.savedFilters) &&
          filterInfo.savedFilters.length > 0 ? (
            filterInfo.savedFilters.map((item) => (
              <option
                key={item.id}
                value={item.id}
                data-filtername={item.filterName}
              >
                {item.filterName}
              </option>
            ))
          ) : (
            <option value="">Select Report</option>
          )}
        </select>

        {/* <Button size="small expand" onClick={handleLoadFilter}>
          Load Filter
        </Button> */}

        <ModalTrigger name="ReportFilterForm">
          <Button size={"small expand"}>Edit Filters</Button>
        </ModalTrigger>
      </Row>

      <Row type="horizontal" className={"displayHeaderRow"}>
        <Button size={"small"} onClick={handlePreview}>
          Preview
        </Button>

        {location.pathname === "/system/report-online-csv-xls" &&
          tableContextFormData.reportId &&
          tableContextFormData.reportId !== "" &&
          tableContextFormData.apiGroup &&
          tableContextFormData.apiGroup !== "" && (
            <Button size={"small"} onClick={handleDownloadReportCSV}>
              Download
            </Button>
          )}
      </Row>

      <Modal name="ReportFilterForm" title={"Report Filter"}>
        <ReportFilter
          reportId={tableContextFormData.reportId}
          reportTypeId={tableContextFormData.reportTypeId}
          selectedFilterId={filterInfo.selectedFilterId}
          setOutsideFormData={setTableContextFormData}
          setOutsideFormData2={setFilterInfo}
        />
      </Modal>
    </div>
  );
}
