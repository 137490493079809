import Hooks from "@hooksFile";
import "../styles/ui/ListItem.scss";

export default function ListItem({ data, type }) {
  const renderContent = (item) => {
    switch (type) {
      case "jobSorHistory":
        return (
          <>
            <p>Version: {item.versionNum}</p>
            <p>SOR Code: {item.sor.sorCode}</p>
            <p>Qty: {item.qty}</p>
            <p>Log Action: {item.logAction}</p>
            <p>Log DateTime: {Hooks.formatISODateToText(item.logDateTime)}</p>
            <p>
              Log User: {item.logStaff.forename} {item.logStaff.surname}
            </p>
            <p>Ref JobSor ID: {item.refId}</p>
          </>
        );
      // add more cases here
      default:
        return <p>No data available for the selected type.</p>;
    }
  };

  return (
    <div className="ListItemContainer">
      {Array.isArray(data) && data.length > 0 ? (
        data.map((item, index) => (
          <div key={index} className="ListItem">
            <div className="ListDetails">{renderContent(item)}</div>
          </div>
        ))
      ) : (
        <p>{data}</p>
      )}
    </div>
  );
}
