import "../styles/ui/DocTagDisplay.scss";

import { useEffect } from "react";
import Button from "./Button";

export default function DocTagDisplay({ data }) {
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <>
      <div className="docTagDisplayContainer">
        <table className="docTagTable">
          <thead>
            <tr className="docTagHeaderRow">
              <th className="docTagHeaderCell">Select</th>
              <th className="docTagHeaderCell">Name</th>
              <th className="docTagHeaderCell">Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="docTagTableRow">
                <td className="docTagTableCell center">
                  <input type="checkbox" className="docTagCheckbox" />
                </td>
                <td className="docTagTableCell">{item.name}</td>
                <td className="docTagTableCell">{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="docTagButtonGroup">
        <Button size="extraSmall">Add To Job Type</Button>
        <Button size="extraSmall">Remove From Job Type</Button>
      </div>
    </>
  );
}
