import React, { useEffect, useState } from "react";
import Hooks from "../../Hooks";

import AddInspectionQuestion from "./Inspections/InspectionSets/AddInspectionQuestion";
import AddPurchaseRequest from "./PurchaseRequests/AddPurchaseRequest";
import AssignContracts from "./Staff/AssignContracts";
import AssignedApis from "./Roles/AssignedApis";
import AssignedReport from "./Roles/AssignedReport";
import AssignedItems from "./Jobs/AssignedItems";
import Attachments from "./Jobs/Attachments/Attachments";
import BigDropdown from "./FormComponents/BigDropdown";
import Inspections from "./Inspections/JobPageInspections/Inspections";
import JobNotes from "./Jobs/JobNotes";
import JobTransitionDropdown from "../sidePanel/SidePanelComponents/JobTransitionDropdown";
import JobTypeInspections from "./JobType/JobTypeInspections";
import MultiContentForm from "./MultiContentForm/MultiContentForm";

import ViewPr from "./PurchaseRequests/ViewPr";
import ViewPO from "./PurchaseOrders/ViewPO";
import ViewDelivery from "./Deliveries/ViewDelivery";
import AddDeliveryDetail from "./Deliveries/AddDeliveryDetail";
import AddReport from "./Reports/AddReport";
import ViewReport from "./Reports/ViewReport";
import AddDefineReport from "../../forms - NEW/07. System/Define Reports/AddDefineReport";
import ViewDefineReport from "../../forms - NEW/07. System/Define Reports/ViewDefineReport";
import PartsBySubCategoryLevel from "./Parts/Part Categories & SubCategories/PartsBySubCategoryLevel";
import PartSearchForm from "./Parts/PartSearchStandalone/PartSearchForm";
import Processes from "../sidePanel/SidePanelComponents/Processes";
import SidePanelRow from "../sidePanel/SidePanelComponents/SidePanelRow";
import AddLetterPrintSetup from "./LetterPrint/AddLetterPrintSetup";
import ViewLetterPrintSetup from "./LetterPrint/ViewLetterPrintSetup";
import ContractPartSearchForm from "./Contracts/ContractParts/ContractPartsForm";
import AddActionEvent from "../../forms - NEW/08. Settings/01. Operations/Action Events/AddActionEvent";
import ViewActionEvent from "../../forms - NEW/08. Settings/01. Operations/Action Events/ViewActionEvent";
import AddApi from "../../forms - NEW/08. Settings/09. Roles/API/AddApi";
import ViewApi from "../../forms - NEW/08. Settings/09. Roles/API/ViewApi";
import AddApiGroup from "../../forms - NEW/08. Settings/09. Roles/API Group/AddApiGroup";
import ViewApiGroup from "../../forms - NEW/08. Settings/09. Roles/API Group/ViewApiGroup";
import AddDocumentTags from "../../forms - NEW/08. Settings/01. Operations/Document Tags/AddDocumentTags";
import ViewDocumentTags from "../../forms - NEW/08. Settings/01. Operations/Document Tags/ViewDocumentTags";
import AddEquipmentCategory from "../../forms - NEW/08. Settings/02. Equipment/Equipment Category/AddEquipmentCategory";
import ViewEquipmentCategory from "../../forms - NEW/08. Settings/02. Equipment/Equipment Category/ViewEquipmentCategory";
import AddEquipmentSubcategory from "../../forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/AddEquipmentSubcategory1";
import ViewEquipmentSubcategory from "../../forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/ViewEquipmentSubcategory1";
import AddEquipmentType from "../../forms - NEW/08. Settings/02. Equipment/Equipment Type/AddEquipmentType";
import ViewEquipmentType from "../../forms - NEW/08. Settings/02. Equipment/Equipment Type/ViewEquipmentType";
import AddClient from "../../forms - NEW/01 .Operations/Clients/AddClient";
import ViewClient from "../../forms - NEW/01 .Operations/Clients/ViewClient";
import AddContract from "../../forms - NEW/01 .Operations/Contracts/AddContract";
import ViewContract from "../../forms - NEW/01 .Operations/Contracts/ViewContract";
import AddProperty from "../../forms - NEW/01 .Operations/Properties/AddProperty";
import ViewProperty from "../../forms - NEW/01 .Operations/Properties/ViewProperty";
import AddJob from "forms - NEW/01 .Operations/Jobs/AddJob";
import ViewJob from "forms - NEW/01 .Operations/Jobs/ViewJob";
import AddInspectionSet from "forms - NEW/01 .Operations/Inspection Sets/AddInspectionSet";
import ViewInspectionSet from "forms - NEW/01 .Operations/Inspection Sets/ViewInspectionSet";
import AddWarehouse from "forms - NEW/02. Warehouse/Warehouses/AddWarehouse";
import ViewWarehouse from "forms - NEW/02. Warehouse/Warehouses/ViewWarehouse";
import AddPart from "forms - NEW/02. Warehouse/Parts/AddPart";
import ViewPart from "forms - NEW/02. Warehouse/Parts/ViewPart";
import AddKit from "forms - NEW/02. Warehouse/Kits/AddKit";
import ViewKit from "forms - NEW/02. Warehouse/Kits/ViewKit";
import AddTicket from "forms - NEW/07. System/Ticket/AddTicket";
import ViewTicket from "forms - NEW/07. System/Ticket/ViewTicket";
import AddTicketType from "forms - NEW/07. System/Ticket Type/AddTicketType";
import ViewTicketType from "forms - NEW/07. System/Ticket Type/ViewTicketType";
import AddAnswerType from "forms - NEW/08. Settings/01. Operations/Answer Type/AddAnswerType";
import ViewAnswerType from "forms - NEW/08. Settings/01. Operations/Answer Type/ViewAnswerType";
import AddObjectDocumentType from "forms - NEW/08. Settings/01. Operations/Object Document Type/AddObjectDocumentType";
import ViewObjectDocumentType from "forms - NEW/08. Settings/01. Operations/Object Document Type/ViewObjectDocumentType";
import AddAttributeCategory from "forms - NEW/08. Settings/01. Operations/Attribite Category/AddAttributeCategory";
import ViewAttributeCategory from "forms - NEW/08. Settings/01. Operations/Attribite Category/ViewAttributeCategory";
import AddAttributeUnit from "forms - NEW/08. Settings/01. Operations/Attribite Unit/AddAttributeUnit";
import ViewAttributeUnit from "forms - NEW/08. Settings/01. Operations/Attribite Unit/ViewAttributeUnit";
import ViewAttributeListChoice from "forms - NEW/08. Settings/01. Operations/Attribite Unit/ViewAttributeListChoice";
import AddStaff from "forms - NEW/08. Settings/08. Staff/Staff/AddStaff";
import ViewStaff from "forms - NEW/08. Settings/08. Staff/Staff/ViewStaff";
import ViewEquipmentDetails from "forms - NEW/01 .Operations/Properties/ViewEquipmentDetails";
import ViewTag from "forms - NEW/08. Settings/01. Operations/Tags/ViewTag";
import AddTag from "forms - NEW/08. Settings/01. Operations/Tags/AddTag";
import AddProgramProcess from "forms - NEW/07. System/View All Program Process/AddProgramProcess";
import ViewProgramProcess from "forms - NEW/07. System/View All Program Process/ViewProgramProcess";
import AddSOR from "forms - NEW/08. Settings/01. Operations/SOR/AddSOR";
import ViewSOR from "forms - NEW/08. Settings/01. Operations/SOR/ViewSOR";
import AddSORToContract from "forms - NEW/01 .Operations/Contracts/AddSORToContract";
import AddSORToJob from "forms - NEW/01 .Operations/Jobs/AddSORToJob";
import ViewJobSors from "forms - NEW/01 .Operations/Jobs/ViewJobSors";
import AddSubJob from "forms - NEW/01 .Operations/Jobs/AddSubJob";
import ViewJobType from "forms - NEW/08. Settings/03. Jobs/ViewJobType";
import AddJobType from "forms - NEW/08. Settings/03. Jobs/AddJobType";
import AddUploadFile from "forms - NEW/01 .Operations/Jobs/AddUploadFile";
import AddSubProperty from "forms - NEW/01 .Operations/Properties/AddSubProperty";
import AddEquipmentSubcategory1 from "../../forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/AddEquipmentSubcategory1";
import ViewEquipmentSubcategory1 from "../../forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/ViewEquipmentSubcategory1";
import AddEquipmentSubcategory2 from "forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/AddEquipmentSubcategory2";
import ViewEquipmentSubcategory2 from "forms - NEW/08. Settings/02. Equipment/Equipment Subcategory/ViewEquipmentSubcategory2";
import AddJobTypeToContract from "forms - NEW/01 .Operations/Contracts/AddJobTypeToContract";
import ViewContractJobTypes from "forms - NEW/01 .Operations/Contracts/ViewContractJobTypes";
import StaffPasswordReset from "forms - NEW/08. Settings/08. Staff/Staff/StaffPasswordReset";
import ViewRole from "forms - NEW/08. Settings/09. Roles/Role/ViewRole";
import AddRole from "forms - NEW/08. Settings/09. Roles/Role/AddRole";
import AssignedUsers from "forms - NEW/08. Settings/09. Roles/Role/AssignedUsers";
import AddUsersToRole from "forms - NEW/08. Settings/09. Roles/Role/AddUsersToRole";
import AddSupplier from "forms - NEW/02. Warehouse/Suppliers/AddSupplier";
import ViewSupplier from "forms - NEW/02. Warehouse/Suppliers/ViewSupplier";
import ViewPartsCategory from "forms - NEW/08. Settings/05. Parts/Parts Category/ViewPartsCategory";
import AddPartsCategory from "forms - NEW/08. Settings/05. Parts/Parts Category/AddPartsCategory";
import ViewPartsAndSuppliers from "forms - NEW/02. Warehouse/Parts/ViewPartsAndSuppliers";
import AddPartsAndSuppliers from "forms - NEW/02. Warehouse/Parts/AddPartsAndSuppliers";
import AddPartsSubcategory1 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 1/AddPartsSubcategory1";
import ViewPartsSubcategory1 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 1/ViewPartsSubcategory1";
import AddPartsSubcategory2 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 2/AddPartsSubcategory2";
import ViewPartsSubcategory2 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 2/ViewPartsSubcategory2";
import AddPartsSubcategory3 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 3/AddPartsSubcategory3";
import ViewPartsSubcategory3 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 3/ViewPartsSubcategory3";
import AddPartsSubcategory4 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 4/AddPartsSubcategory4";
import ViewPartsSubcategory4 from "forms - NEW/08. Settings/05. Parts/Parts Subcategory 4/ViewPartsSubcategory4";
import ViewPartSubCategory from "./Parts/Part Categories & SubCategories/ViewPartSubCategory";
import AddVisit from "forms - NEW/01 .Operations/Jobs/AddVisit";
import ViewVisit from "forms - NEW/01 .Operations/Jobs/ViewVisit";
import AddDocumentWorkflow from "forms - NEW/08. Settings/01. Operations/Document Workflow/0_SidePanel Forms/AddDocumentWorkflow";
import ViewDocumentWorkflow from "forms - NEW/08. Settings/01. Operations/Document Workflow/0_SidePanel Forms/ViewDocumentWorkflow";

export default function GenerateForm({
  state,
  setFormState, // use to set formState>type like setFormState({ type: "addparttokit", data: {} }); within a form
  viewFlyout,
  searchClicked,
  searchId,
  partSearch,
  selectedPart,
  updatePopup,
  refresh,
  refreshTable,
  setFooterState,
  cleanURL,
  type,
  archive,
  addInspecQuesFunc,
}) {
  const [form, setForm] = useState([]);

  // useEffect(() => {
  //   console.log("state", state);
  //   // console.log("form", form);
  // }, [state, form]);

  useEffect(() => {
    // This is what the auto filled forms use instead of individual/manual forms
    // The object contains the formattedType as the key & the array has the inputs to display.
    const formStates = {
      addapi: ["API Group"],
      addcontracttoproperty: ["Contract To Property"], // sidePanel Tab form.
      adddocumenttag: ["Document Tag"],

      addequipment: [
        "EquipmentTypeSearch",
        "Equip Type",
        "Make",
        "Model",
        "Name",
        "Anniversary Date",
        "Location",
        "Equipment Notes",
      ],

      addjobtoproperty: [
        "Contract",
        "Engineer",
        "Job State",
        "Job Type",
        "Priority",
        "Invoiced",
        "Target Date",
        "Scheduled Date",
        "Completed Date",
        "Reference",
      ],

      addparttokit: ["Search for Part", "Part Number", "Quantity"],
      addkit: ["Name", "Quantity In Stock"],

      calendar: [
        "Property ID",
        "Contract",
        "Reference",
        "Notes",
        "Engineer",
        "Job State",
        "Job Type",
        "Scheduled Date",
        "Target Date",
        "Record Created",
        "Record Modified",
      ],

      changepassword: ["New Password", "Confirm Password"],

      cancelreason: ["Reason"],
      addnewcancelreason: ["Reason"],
      assigncancelreason: ["Cancel Reason"],

      certifications: [
        "Name",
        "Description",
        "Date From",
        "Date Expire",
        "Cert Number",
      ],
      addnewcertification: [
        "Name",
        "Description",
        "Date From",
        "Date Expire",
        "Cert Number",
        "Engineer",
      ],

      assigninspectionset: ["Inspection Set"],
      viewinspectionsets: [],

      // This is within the "Add Part To Job" section. It could be removed once my form is in place
      addparttojob: ["Search For Part", "Part Number"],

      jobcategory: ["Description"],
      addnewjobcategory: ["Description"],
      jobstate: ["Description"],
      addnewjobstate: ["Description"],
      jobstatetransitions: ["Job Transition Drop"],
      addnewjobstatetransition: [
        "Job Type",
        "Current Job State",
        "Next Job State",
      ],

      reschedulejob: ["Scheduled Date"],

      partdetails: ["Part Number", "Description"],

      priority: ["Name"],
      addnewpriority: ["Name"],

      addvisitproperty: [
        "Engineer",
        "Visit State",
        "Assign Job",
        "Booked At",
        "Logged At",
        "Scheduled For",
        "Completed At",
        "Notes",
      ],

      qcstatus: ["Description"],
      addnewqcstatus: ["Description"],

      assignrole: ["Role"],

      addnewstafftype: ["Description"],
      assignstaff: ["Engineer"],
      staffcertifications: [
        "Name",
        "Description",
        "Date From",
        "Date Expire",
        "Cert Number",
      ],
      stafftype: ["Description"],
      updatestaff: ["Engineer"],

      addnewstock: [
        "Search For Part",
        "Part Number",
        "Quantity",
        "Arrival Date",
        "Warehouse Location",
      ],
      stock: [
        "Warehouse",
        "Is Van",
        "Aisle",
        "Rack",
        "Level",
        "Stock State",
        "Parts Number",
        "Quantity",
        "Arrival Date",
        "Job Booked",
        "Allocated By PR",
        "From PO",
        "GRN",
        "Last Updated By",
        "Stock Action",
      ],

      addnewstockmovements: [
        "Source Warehouse",
        "Destination Warehouse",
        "Parts",
        "Quantity",
        "Date",
      ],
      stockmovements: [
        "Source Warehouse",
        "Destination Warehouse",
        "Parts",
        "Quantity",
      ],

      addstocktowarehouse: ["Aisle", "Rack", "Level"],
      importtostock: ["Directly Allocate to Job"],

      stockcyclecount: [
        "Name",
        "Is Open",
        "Start Date",
        "End Date",
        "Responsible Staff",
      ],

      addpartsupplier: [
        "Search for Supplier",
        "Supplier Name",
        "Supplier Part No",
        "Unit Cost",
      ],
      addsupplierpart: [
        "Search for Part",
        "Part Number",
        "Quantity",
        "Supplier Part No",
        "Unit Cost",
      ],

      visitstate: ["Description"],
      addnewvisitstate: ["Description"],
      visitstatetransitions: [
        "Job Type",
        "Current Visit State",
        "Next Visit State",
      ],
      addnewvisitstatetransition: [
        "Job Type",
        "Current Visit State",
        "Next Visit State",
      ],

      // Current
      warehouses: ["Is Van", "Name", "Address"],
      addnewwarehouses: ["Is Van", "Name", "Address"],

      addresident: [
        "Primary",
        "Title",
        "Forename",
        "Surname",
        "Mobile",
        "Email",
        "Landline",
      ],

      addscheduling: [
        "Look ahead",
        "Flexibility",
        "Period",
        "MOT",
        "Description",
        "Job Type Schedule",
        "ScheduleTimePicker",
      ],

      //! DELETE
      addvisit: [
        "Engineer",
        "Estimated Arrival Time",
        "Actual Arrival Time",
        "Departure Time",
        "Logged At",
        "Scheduled For",
        // "Visit Notes",
      ],

      currentvisits: [
        "Logged At",
        "Completed At",
        "Notes",
        "Id",
        "Property Id",
        "Engineer",
        "Name",
        "Description.",
      ],
      visitdetails: [
        "ID",
        "Scheduled For",
        "Completed At",
        "Notes",
        "Job Ref ID",
        "Job State Ref ID",
        "Job Type Ref ID",
        "User Ref ID",
      ],

      assignEngineer: ["Engineer"],

      newpart: ["Part Number", "Description"],

      processes: ["Message"],

      addnewpurchaseorder: ["Supplier", "Description", "Target Date"],
      purchaserequests: [
        "Part",
        "Quantity",
        "Unit Price",
        "Target Date",
        "Target Job",
        "Target Warehouse",
        "Target Supplier",
        "Reason",
      ],
      purchaseorders: [
        "Supplier",
        "PurchaseOrderState",
        "Requested By",
        "Raised On",
        "Target Date",
        "Description",
      ],

      addnewdelivery: [
        "Warehouse Location",
        "Supplier",
        "Delivery Note Number",
        "Delivery Date",
      ],

      addpodetail: [
        "Warehouse Location",
        "Search For Part",
        "Part Number",
        "PO Unit Cost",
        "Delivery Date",
        "Quantity",
        "Target Job",
      ],

      addnewcostcode: ["Name", "Description", "Cost Type"],
      addnewcosttype: ["Name"],
      costcodes: ["Name", "Description", "Cost Type"],
      costtypes: ["Name"],
    };
    // Check for how many keys within formStates
    // console.log("formStates containes:", Object.keys(formStates).length);

    const form = [];
    const formattedType = Hooks.conditionalString(state?.type);

    // . check the type value with this console.log to add to the switch statement
    // console.log("state", state);
    // console.log("state.data", state.data);
    console.log("formattedType:", formattedType);

    // This is where the individual/manual forms are
    switch (formattedType) {
      // * PAGE GROUP: Operations --------------------------------------------------------------------

      // . Clients
      case "addnewclient":
        form.push(
          <AddClient
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "clients":
        form.push(
          <ViewClient
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Contracts
      case "addnewcontract":
        form.push(
          <AddContract
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "contracts":
        form.push(
          <ViewContract
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      // . Contracts > SORs
      case "addnewsortocontract":
        form.push(
          <AddSORToContract
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      // . Contracts > Job Types
      case "contractjobtypes":
        form.push(
          <ViewContractJobTypes
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      // . Contracts > Job Types > Add Job Type
      case "assignjobtype":
        form.push(
          <AddJobTypeToContract
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Properties
      case "addnewproperty":
        form.push(
          <AddProperty
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "properties":
        form.push(
          <ViewProperty
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      // . Properties > Add Sub Property
      case "addsubproperty":
        form.push(
          <AddSubProperty
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      // . Properties > Equipment Details
      case "equipmentdetails":
        form.push(
          <ViewEquipmentDetails
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Jobs
      case "addnewjob":
        form.push(
          <AddJob
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "jobs":
        form.push(
          <ViewJob
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > Add Sub Job
      case "addsubjob":
        form.push(
          <AddSubJob
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > View Visits
      case "visit":
        form.push(
          <ViewVisit
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > Add Visit
      case "addvisit":
        form.push(
          <AddVisit
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > Job SORs
      case "jobsors":
        form.push(
          <ViewJobSors
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > Job SORs > Add SOR To Job
      case "addsortojob":
        form.push(
          <AddSORToJob
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      //. Jobs > Attachments > Upload File
      case "uploadfile":
        form.push(
          <AddUploadFile
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Inspection Sets
      case "addnewinspectionset":
        form.push(
          <AddInspectionSet
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "inspectionsets":
        form.push(
          <ViewInspectionSet
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            addInspectionQuestion={addInspecQuesFunc}
            setFormState={setFormState}
          />
        );
        break;

      // * PAGE GROUP: Warehouse --------------------------------------------------------------------

      // . Warehouses
      case "addnewwarehouses":
        form.push(
          <AddWarehouse
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "warehouses":
        form.push(
          <ViewWarehouse
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Parts
      case "addnewpart":
        form.push(
          <AddPart
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "parts":
        form.push(
          <ViewPart
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      //. Parts > Parts & Supplier
      case "partsupplier":
        form.push(
          <ViewPartsAndSuppliers
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      case "addpartsandsupplier":
        form.push(
          <AddPartsAndSuppliers
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Kits
      case "addnewkit":
        form.push(
          <AddKit
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "kits":
        form.push(
          <ViewKit
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Suppliers
      case "addnewsupplier":
        form.push(
          <AddSupplier
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "suppliers":
        form.push(
          <ViewSupplier
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // ! These 4 can be done later
      // . Stock
      // . Stock Movements
      // . Goods Receiving
      // . Stock Cycle Count

      // * PAGE GROUP: Purchasing --------------------------------------------------------------------
      // . Purchase Requests
      // . Purchase Orders

      // * PAGE GROUP: Finance --------------------------------------------------------------------

      // * PAGE GROUP: Estimating --------------------------------------------------------------------

      // * PAGE GROUP: Compliance --------------------------------------------------------------------

      // * PAGE GROUP: System --------------------------------------------------------------------
      // . Define Reports
      case "addnewrunreportlist":
        form.push(
          <AddDefineReport
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "definereports":
        form.push(
          <ViewDefineReport
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Report Online
      // ! Currently are not used
      case "reportonline":
        // form.push();
        break;
      case "addnewrunreportasonlinelog":
        // form.push();
        break;

      // . Report as File or Email
      // ! Non RHF
      case "addnewrunreportasfileoremail":
        form.push(
          <AddReport
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "reportasfileoremail":
        form.push(
          <ViewReport
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . View Report Process
      // ! Non RHF
      case "addnewrunreportproc":
        form.push(
          <AddReport
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "viewreportprocess":
        form.push(
          <ViewReport
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            isViewReportProcess={true}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Custom Process
      case "addnewruncustomprocess":
        // form.push();
        break;
      case "customprocess":
        // form.push();
        break;
      // . Letter Print Setup
      case "addnewletterprintsetup":
        form.push(
          <AddLetterPrintSetup
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "letterprintsetup":
        form.push(
          <ViewLetterPrintSetup
            key={formattedType}
            state={state}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      //. View All Program Process
      case "addnewviewallprogramprocess":
        form.push(
          <AddProgramProcess
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "viewallprogramprocess":
        form.push(
          <ViewProgramProcess
            key={formattedType}
            state={state}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Ticket
      case "addnewticket":
        form.push(
          <AddTicket
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "ticket":
        form.push(
          <ViewTicket
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Ticket Type
      case "addnewtickettype":
        form.push(
          <AddTicketType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "tickettype":
        form.push(
          <ViewTicketType
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // * PAGE GROUP: Settings --------------------------------------------------------------------
      // * SUB GROUP: Operations --------------------------------------------------------------------
      // . Action Events
      case "addnewactionevent":
        form.push(
          <AddActionEvent
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "actionevent":
        form.push(
          <ViewActionEvent
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Document Tags
      case "addnewdocumenttag":
        form.push(
          <AddDocumentTags
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "documenttags":
        form.push(
          <ViewDocumentTags
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Cancel Reason
      // . Priority
      // . QC Status
      // . SOR
      case "addnewsor":
        form.push(
          <AddSOR
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "sor":
        form.push(
          <ViewSOR
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Answer Type
      case "addnewanswertype":
        form.push(
          <AddAnswerType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "answertype":
        form.push(
          <ViewAnswerType
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      // . Object Document Type
      case "addnewobjectdocumenttype":
        form.push(
          <AddObjectDocumentType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "objectdocumenttype":
        form.push(
          <ViewObjectDocumentType
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      // . Attribute Category
      case "addnewattributecategory":
        form.push(
          <AddAttributeCategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "attributecategory":
        form.push(
          <ViewAttributeCategory
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Attribute Unit
      case "addnewattributeunit":
        form.push(
          <AddAttributeUnit
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "attributeunit":
        form.push(
          <ViewAttributeUnit
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "attributelistchoice":
        form.push(
          <ViewAttributeListChoice
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Tags
      case "addnewtags":
        form.push(
          <AddTag
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "tags":
        form.push(
          <ViewTag
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Document Workflow
      case "addnewdocumentworkflow":
        form.push(
          <AddDocumentWorkflow
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "documentworkflow":
        form.push(
          <ViewDocumentWorkflow
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // * SUB GROUP: Equipment --------------------------------------------------------------------
      // . Equipment Category
      case "addnewequipmentcategory":
        form.push(
          <AddEquipmentCategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "equipmentcategory":
        form.push(
          <ViewEquipmentCategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Equipment Subcategory
      case "addnewequipmentsubcategory":
        form.push(
          <AddEquipmentSubcategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "equipmentsubcategory":
        form.push(
          <ViewEquipmentSubcategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Equipment Subcategory 1
      case "addnewequipmentsubcategory1":
        form.push(
          <AddEquipmentSubcategory1
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "equipmentsubcategory1":
        form.push(
          <ViewEquipmentSubcategory1
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      // . Equipment Subcategory 2
      case "addnewequipmentsubcategory2":
        form.push(
          <AddEquipmentSubcategory2
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "equipmentsubcategory2":
        form.push(
          <ViewEquipmentSubcategory2
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Equipment Type
      case "addnewequipmenttype":
        form.push(
          <AddEquipmentType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "equipmenttype":
        form.push(
          <ViewEquipmentType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // * SUB GROUP: Jobs --------------------------------------------------------------------

      case "addnewjobtype":
        form.push(
          <AddJobType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "jobtype":
        form.push(
          <ViewJobType
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // * SUB GROUP: Visits --------------------------------------------------------------------

      // * SUB GROUP: Parts --------------------------------------------------------------------
      //. Parts Category
      case "addnewpartscategory":
        form.push(
          <AddPartsCategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "partcategories":
        form.push(
          <ViewPartsCategory
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      //. Parts Subcategory 1
      case "addnewpartssubcategory1":
        form.push(
          <AddPartsSubcategory1
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "partssubcategory1":
        form.push(
          <ViewPartsSubcategory1
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      //. Parts Subcategory 2
      case "addnewpartssubcategory2":
        form.push(
          <AddPartsSubcategory2
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "partssubcategory2":
        form.push(
          <ViewPartsSubcategory2
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      //. Parts Subcategory 3
      case "addnewpartssubcategory3":
        form.push(
          <AddPartsSubcategory3
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "partssubcategory3":
        form.push(
          <ViewPartsSubcategory3
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      //. Parts Subcategory 4
      case "addnewpartssubcategory4":
        form.push(
          <AddPartsSubcategory4
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "partssubcategory4":
        form.push(
          <ViewPartsSubcategory4
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // * SUB GROUP: Purchasing --------------------------------------------------------------------

      // * SUB GROUP: Finance --------------------------------------------------------------------

      // * SUB GROUP: Staff --------------------------------------------------------------------
      // . Staff
      case "addnewstaff":
        form.push(
          <AddStaff
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "staff":
        form.push(
          <ViewStaff
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "staffpasswordreset":
        form.push(
          <StaffPasswordReset
            key={formattedType}
            setFooterState={setFooterState}
            state={state}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            archive={archive}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // . Staff Type

      // . Certificates

      // * SUB GROUP: Roles --------------------------------------------------------------------
      // . Role
      case "addnewrole":
        form.push(
          <AddRole
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "role":
        form.push(
          <ViewRole
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      // . Role > Assigned User
      case "assignedusers":
        form.push(
          <AssignedUsers
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . Role > Assigned User > Add User To Role
      case "adduserstorole":
        form.push(
          <AddUsersToRole
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . API
      case "addnewapi":
        form.push(
          <AddApi
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;
      case "api":
        form.push(
          <ViewApi
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;

      // . API Group
      case "apigroup":
        form.push(
          <ViewApiGroup
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            state={state}
            archive={archive}
            setFormState={setFormState}
          />
        );
        break;
      case "addnewapigroup":
        form.push(
          <AddApiGroup
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
            setFormState={setFormState}
          />
        );
        break;

      // ! OLD --------------------------------------------------------------------
      case "addparttojob":
        form.push(
          <PartSearchForm
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
          />
        );
        break;

      case "contractparts":
        form.push(
          <ContractPartSearchForm
            key={formattedType}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
          />
        );
        break;

      case "adddeliverydetail":
        form.push(<AddDeliveryDetail key={formattedType} apiUrl={type} />);
        break;

      // Parts Sub Category
      case "partssubcategory":
        form.push(
          <ViewPartSubCategory
            key={formattedType}
            state={state}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
          />
        );
        break;

      case "partsbysubcategorylevel":
        form.push(
          <PartsBySubCategoryLevel
            key={formattedType}
            state={state}
            setFooterState={setFooterState}
            cleanURL={cleanURL}
            refreshTable={refreshTable}
            apiUrl={type}
          />
        );
        break;

      case "deliveries":
        form.push(
          <ViewDelivery
            refresh={refresh}
            key={`viewDelivery_${Hooks.getSidePanelData()?.id}`}
            updatePopup={updatePopup}
            deliveryId={Hooks.getSidePanelData()?.id}
            searchForPart={partSearch}
            selectedPart={selectedPart}
            apiUrl={type}
          />
        );
        break;
      case "purchaseorders":
        form.push(
          <ViewPO
            key={`viewPo_${Hooks.getSidePanelData()?.id}`}
            purchaseOrderId={Hooks.getSidePanelData()?.id}
            searchClicked={searchClicked}
            apiUrl={type}
          />
        );
        break;
      case "purchaserequests":
        const id = Hooks.getSidePanelData().id;
        form.push(
          <ViewPr
            key={`viewPr_${id}`}
            purchaseReqId={id}
            searchClicked={searchClicked}
            apiUrl={type}
          />
        );
        break;
      case "addnewpurchaserequest":
        form.push(
          <AddPurchaseRequest
            key={formattedType}
            partSearch={partSearch}
            search={searchClicked}
            selectedPart={selectedPart}
            apiUrl={type}
          />
        );
        break;
      case "jobstatetransitions":
        form.push(
          <JobTransitionDropdown
            key={formattedType}
            apiUrl={type}
            jobTypeValue={{
              label: state?.data?.jobType?.description,
              value: state?.data?.jobTypeRefId,
            }}
            jobStateValue={{
              label: state?.data?.currJobState?.description,
              value: state?.data?.currJobStateId,
            }}
            nextJobStateValue={{
              label: state?.data?.nextJobState?.description,
              value: state?.data?.nextJobStateId,
            }}
          />
        );
        break;
      case "assignedapis":
        // console.log("state", state);
        form.push(
          <AssignedApis data={state?.data} key={formattedType} apiUrl={type} />
        );
        break;
      case "assignedreport":
        // console.log("state", state);
        form.push(
          <AssignedReport
            data={state?.data}
            key={formattedType}
            apiUrl={type}
          />
        );
        break;
      case "jobnotes":
        form.push(
          <JobNotes jobId={state?.data} key={formattedType} apiUrl={type} />
        );
        break;
      case "assigninspectionset":
        form.push(
          <BigDropdown
            title="Inspection Set"
            key={formattedType}
            apiUrl={type}
          />
        );
        break;
      case "addstaffcontract":
        form.push(<AssignContracts key={formattedType} apiUrl={type} />);
        break;
      case "viewinspectionsets":
        form.push(<JobTypeInspections key={formattedType} apiUrl={type} />);
        break;
      case "addinspectionquestion":
        form.push(<AddInspectionQuestion key={formattedType} apiUrl={type} />);
        break;
      case "viewprocesses":
        form.push(<Processes key={formattedType} apiUrl={type} />);
        break;
      case "inspections":
        try {
          const answered = state?.data.some((item) => item.answered);

          form.push(
            <Inspections
              key={formattedType}
              data={state?.data}
              answered={answered}
              apiUrl={type}
            />
          );
        } catch (e) {
          console.log(e);
        }
        break;
      case "attachments":
        form.push(
          <Attachments
            jobId={Hooks.getSidePanelData()?.id}
            key={formattedType}
            apiUrl={type}
          />
        );
        break;
      case "nocontractstaff":
        form.push(
          <div key="noContract" className="contentInfo">
            <p className="sidePanelKey">No staff assigned to this contract.</p>
          </div>
        );
        break;

      default:
        const pageTitle = Hooks.conditionalString(
          document.querySelector("#titleContainerText").textContent
        );
        // Forms using the AssignedItems component.
        const assignedItemsForms = [
          "staffassigned",
          "vanengineers",
          "documenttags",
          "viewinspectionsets",
          "cancelreasoncontracts",
          "staffassigned",
          "assignedcontracts",
          "managerole",
          "apigroups",
          "sors",
          // "jobsors", //. See as example of refactoring
        ];
        // Forms using the MultiContentForm component.
        const multiForms = [
          "partsupplier", // Old version, creating new form
          "stocklocation",
          "supplierparts",
          "staffcontract",
          "staffcertifications",
          "visit",
          "assignedjobs",
          "resident",
          "jobparts",
          "scheduling",
          // "equipmentdetails", //. See as example of refactoring
        ];
        // Forms which are marked as Read Only.
        const readOnlyForms = ["stock", "stockmovements"];

        // This is within the "Add Part To Job" section. It could be removed once my form is in place
        const longInputs = ["searchforpart"];

        if (
          pageTitle !== "documenttags" &&
          assignedItemsForms.includes(state?.type)
        ) {
          // console.log("AssignedItems Triggered");
          form.push(
            <AssignedItems
              data={state?.data}
              type={state?.type}
              key={state?.type}
              setFormState={setFormState}
            />
          );
        } else if (multiForms.includes(state?.type)) {
          // console.log("state?.data", state?.data);
          // console.log("MultiContentForm Triggered");
          form.push(
            <MultiContentForm
              key={state?.type}
              data={state?.data}
              type={state?.type}
              selectedPart={selectedPart}
            />
          );
        } else {
          if (
            !formStates ||
            !formStates[state?.type] ||
            !formStates[state?.type].length
          ) {
            return;
          }

          formStates[state?.type].forEach((key) => {
            const formattedKey = Hooks.conditionalString(key);
            const resetKey = Hooks.resetKey(key);

            // This is within the "Add Part To Job" section. It could be removed once my form is in place
            if (longInputs.includes(Hooks.conditionalString(key))) {
              // console.log("SidePanelRow-4 Triggered");
              form.push(
                <SidePanelRow
                  search={searchClicked}
                  searchId={searchId}
                  key={key}
                  title={key}
                  value={state?.data[resetKey]}
                  fullData={state?.data}
                  viewFlyout={viewFlyout}
                  searchClicked={searchClicked}
                  long={true}
                  partSearch={partSearch}
                />
              );
            } else if (
              readOnlyForms.includes(Hooks.conditionalString(state?.type))
            ) {
              // console.log("SidePanelRow-3 Triggered");
              form.push(
                <SidePanelRow
                  key={key}
                  title={key}
                  value={state?.data[resetKey]}
                  fullData={state?.data}
                  viewFlyout={viewFlyout}
                  readOnly
                />
              );
            } else if (formattedKey === "address") {
              // console.log("SidePanelRow-2 Triggered");
              form.push(
                <SidePanelRow
                  key={key}
                  title={key}
                  value={state?.data}
                  fullData={state?.data}
                  searchClicked={searchClicked}
                />
              );
            } else {
              // Here
              // console.log("SidePanelRow-1 Triggered");
              form.push(
                <SidePanelRow
                  selectedPart={selectedPart}
                  search={searchClicked}
                  searchId={searchId}
                  key={key}
                  title={key}
                  value={state?.data[resetKey]}
                  fullData={state?.data}
                  viewFlyout={viewFlyout}
                  searchClicked={searchClicked}
                />
              );
            }
          });
          break;
        }
    }

    setForm(form);
  }, [
    state,
    setFormState,
    viewFlyout,
    searchClicked,
    partSearch,
    selectedPart,
    searchId,
    refresh,
    updatePopup,
    setFooterState,
    cleanURL,
    refreshTable,
    type,
    archive,
    addInspecQuesFunc,
  ]);

  return form;
}
