import "./AssignedItems.scss";

import React, { useState, useEffect } from "react";
import Hooks from "../../../Hooks";
import Swal from "sweetalert2";
import API_ENV from "@config";
import Button from "@ui/Button";

export default function AssignedItems({ data, type, setFormState }) {
  const [contents, updateContents] = useState([]);

  // useEffect(() => {
  //   console.log("data", data);
  //   console.log("type", type);
  //   // console.log("setFormState", setFormState);
  //   // console.log("contents", contents);
  // }, [type]);

  useEffect(() => {
    const types = {
      assignedusers: {
        displayKey: "",
        deleteUrl: `${API_ENV}/staffToRole`,
        getUrl: `${API_ENV}/staffToRole?RoleId=`,
        emptyMessage: "No users are assigned to this role.",
      },
      cancelreasoncontracts: {
        displayKey: "",
        deleteUrl: `${API_ENV}/contractsAndCancelReason`,
        emptyMessage: "No cancel reasons are assigned to this contract.",
      },
      viewinspectionsets: {
        displayKey: "name",
        deleteUrl: `${API_ENV}/jobTypeAndInspectionSet`,
        emptyMessage: "No inspection sets are assigned to this job type.",
      },
      staffcontract: {
        displayKey: "name",
        deleteUrl: `${API_ENV}/contractsAndEngineers?contractIds=`,
        emptyMessage: "No contracts are assigned to this staff member.",
      },
      contractjobtypes: {
        displayKey: "description",
        deleteUrl: `${API_ENV}/contractsAndJobType`,
        getUrl: `${API_ENV}/jobType/contract`,
        emptyMessage: "No job types are assigned to this contract.",
      },
      staffassigned: {
        displayKey: "",
        deleteUrl: `${API_ENV}/contractsAndEngineers`,
        emptyMessage: "No staff are assigned to this contract.",
      },
      assignedcontracts: {
        displayKey: "name",
        deleteUrl: `${API_ENV}/contractsAndProperties`,
        emptyMessage: "No contracts are assigned to this property.",
      },
      staffcertifications: {
        displayKey: "name",
        deleteUrl: `${API_ENV}/certifications`,
        emptyMessage: "No certifications are assigned to this staff member.",
      },
      managerole: {
        displayKey: "",
        deleteUrl: `${API_ENV}/staffToRole`,
        emptyMessage: "No roles are assigned to this staff member.",
      },
      assignedapis: {
        displayKey: "",
        deleteUrl: "",
        emptyMessage: "No APIs are assigned to this role.",
      },
      apigroups: {
        displayKey: "groupName",
        deleteUrl: `${API_ENV}/ApiGroup`,
        emptyMessage: "No APIs are assigned to this role.",
      },
      sors: {
        displayKey: "sorCode",
        deleteUrl: `${API_ENV}/contractsAndSor`,
        emptyMessage: "No SORs are assigned to this contract.",
      },
      jobsors: {
        displayKey: "sorCode",
        deleteUrl: `${API_ENV}/jobSor`,
        emptyMessage: "No SORs are assigned to this Job.",
      },
      documenttags: {
        displayKey: "name",
        deleteUrl: `${API_ENV}/jobTypeAndDocumentTag`,
        emptyMessage: "No document tags are assigned to this job type.",
      },
      partsupplier: {
        displayKey: "",
        deleteUrl: `${API_ENV}/partsAndSupplier`,
        emptyMessage: "No suppliers are assigned to this part.",
      },
      stocklocation: {
        displayKey: "",
        deleteUrl: `${API_ENV}/stockLocation?WarehouseID=`,
        emptyMessage: "No stock locations assigned to this warehouse.",
      },
      supplierparts: {
        displayKey: "id",
        deleteUrl: "",
        emptyMessage: "No parts assigned to this supplier.",
      },
      vanengineers: {
        displayKey: "id",
        deleteUrl: `${API_ENV}/engineersAndVan`,
        emptyMessage: "No engineers assigned to this van.",
      },
    };

    function deleteClick(target) {
      // console.log("target", target);

      Swal.fire({
        title: "Unassign item?",
        text: "Are you sure you wish to remove this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const assignedId = target.parentElement.getAttribute("data");
          const sidePanelId = Hooks.getSidePanelData().id;

          function thenFunction(response) {
            if (response.ok) {
              // document.querySelector(".currentTab").click();

              const url = types[type].getUrl || types[type].deleteUrl;

              // console.log("url", url);

              if (["assignedusers"].includes(type)) {
                Hooks.getData(`${url}${sidePanelId}`, (data) => {
                  // console.log("useEffect API 1 data:", data);
                  setFormState({ type: type, data });
                });
              } else if (["partsupplier"].includes(type)) {
                Hooks.getData(
                  `${API_ENV}/partsAndSupplier?PartsID=${sidePanelId}`,
                  (data) => {
                    // console.log("useEffect API 1 data:", data);
                    setFormState({ type: type, data });
                  }
                );
              } else {
                Hooks.getData(`${url}/${sidePanelId}`, (data) => {
                  // console.log("useEffect API 1 data:", data);
                  setFormState({ type: type, data });
                });
              }
            }
          }

          if (assignedId && sidePanelId) {
            if (
              [
                "vanengineers",
                "cancelreasoncontracts",
                "documenttags",
                "staffassigned",
                "contractjobtypes",
                "assignedcontracts",
              ].includes(type)
            ) {
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${assignedId}/${sidePanelId}`,
                "DELETE",
                thenFunction
              );
            } else if (["managerole", "partsupplier"].includes(type)) {
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${assignedId}`,
                "DELETE",
                thenFunction
              );
            } else if (type === "sors") {
              // /contractsAndSor/{sorId}/{contractId}
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${assignedId}/${sidePanelId}`,
                "DELETE",
                thenFunction
              );
            } else if (type === "jobsors") {
              // /jobsor/{jobId}/{sorId}
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${sidePanelId}/${assignedId}`,
                "DELETE",
                thenFunction
              );
            } else if (type === "assignedusers") {
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${assignedId}`,
                "DELETE",
                thenFunction
              );
            } else {
              Hooks.sendData(
                {},
                `${types[type].deleteUrl}/${sidePanelId}/${assignedId}`,
                "DELETE",
                thenFunction
              );
            }
          }
        }
      });
    }

    function getName(type, data) {
      const condType = Hooks.conditionalString(type);
      // console.log("condType", condType);
      // console.log("data", data);

      try {
        switch (condType) {
          case "assignedusers":
            return `Staff: ${data?.staff?.forename} ${data?.staff?.surname} | Role: ${data?.role?.name}`;

          case "sors":
            return `Code: ${data?.sorCode} | Desc: ${data?.shortDesc} | Rate: ${
              data?.rate
            } | UOM: ${data?.uom ? data?.uom?.name : "No UOM Found"}`;

          case "jobsors":
            return `Code: ${data?.sor?.sorCode} | Desc: ${
              data?.sor?.shortDesc
            } | Rate: ${data?.sor?.rate} | UOM: ${
              data?.sor?.uom ? data?.sor?.uom?.name : "No UOM Found"
            }`;

          case "stocklocation":
            return `${data?.aisle} | ${data?.rack} | ${data?.level}`;
          case "supplierparts":
            return data?.parts?.partno;
          case "partsupplier":
            return `Part: ${data?.parts?.partno} | Supplier: ${data?.supplier?.name} `;
          case "cancelreasoncontracts":
            return data?.cancelReason?.reason;
          case "assignedapis":
            return data?.api?.name;
          case "managerole":
            return data?.role?.name;
          case "vanengineers":
            return `${data?.forename} ${data?.surname}`;
          case "staffassigned":
            return `${data?.forename} ${data?.surname}`;
          default:
            const displayKey = types[type]?.displayKey || "name";
            return data?.[displayKey];
        }
      } catch (e) {
        return "Error loading name.";
      }
    }

    function setElement(type, data, index) {
      // console.log("type", type);
      //   console.log("data", data);
      const idProp =
        {
          cancelreasoncontracts: "cancelReasonId",
          staffassigned: "engineerId",
          jobsors: "sorRefId",
        }[type] || "id";

      // console.log("idProp", idProp);

      const dataId = data[idProp];

      return (
        <div
          className="assignedRow"
          id={dataId}
          data={dataId}
          key={`${dataId}-${index}`}
        >
          <div className="assignedName">{getName(type, data)}</div>
          <Button
            type="danger"
            id={dataId}
            data={dataId}
            onClick={(e) => {
              e.preventDefault();
              deleteClick(e.target);
            }}
          >
            Delete
          </Button>
        </div>
      );
    }

    if (data && data.length && typeof data !== "string") {
      let array = data.map((set, index) => setElement(type, set, index)); // Use map for transformation
      updateContents(array);
    } else if (types[type] && data) {
      // Ensure `data` is defined before rendering the message
      updateContents(
        <div className="contentInfo">{types[type].emptyMessage}</div>
      );
    } else {
      updateContents([]); // Render nothing if data is undefined
    }
  }, [data, type, setFormState]);

  return (
    <div id="assignedSets" key={data ? data.id : "default-key"}>
      {contents}
    </div>
  );
}
